import {
  ServiceTopic,
  ServiceTopicData,
  serviceTopicCollectionKey,
  convertToServiceTopic
} from "@/entities/service_topic";
import firebase from "firebase/app";
import "firebase/firestore";
import store from "@/store";

export async function getServiceTopics(): Promise<ServiceTopic[]> {
  const db = firebase.firestore();

  const snapshot = await db.collection(serviceTopicCollectionKey).get();
  if (snapshot.empty) {
    return [];
  }

  return snapshot.docs
    .map(doc => convertToServiceTopic(doc.data(), doc.ref))
    .sort((a, b) => b.data.timestamp - a.data.timestamp);
}

export async function getServiceTopicsOfSchool(): Promise<ServiceTopic[]> {
  const schoolRef = store.state.school?.ref;
  const db = schoolRef;
  if (!db) {
    return [];
  }

  const snapshot = await db.collection(serviceTopicCollectionKey).get();
  if (snapshot.empty) {
    return [];
  }

  return snapshot.docs
    .map(doc => convertToServiceTopic(doc.data(), doc.ref))
    .sort((a, b) => b.data.timestamp - a.data.timestamp);
}

export async function createServiceTopic({
  url,
  label,
  timestamp
}: {
  url: string;
  label: string;
  timestamp: number;
}): Promise<{ id: string; data: ServiceTopicData }> {
  const roleType = store.state.role!.data.type;

  let db;
  if (roleType === "admin") {
    db = firebase.firestore();
  } else if (roleType === "tutor") {
    const schoolRef = store.state.school?.ref;
    db = schoolRef;
  } else {
    db = null;
  }

  if (!db) {
    throw new Error("データベースへのアクセス権がありません");
  }

  const ref = await db?.collection(serviceTopicCollectionKey).add({
    url,
    label,
    timestamp
  });
  return {
    id: ref!.id,
    data: {
      url,
      label,
      timestamp
    } as ServiceTopicData
  };
}

export async function updateServiceTopic(
  ref: firebase.firestore.DocumentReference,
  {
    url,
    label,
    timestamp
  }: {
    url?: string;
    label?: string;
    timestamp?: number;
  }
): Promise<{ id: string; data: ServiceTopicData } | null> {
  const snapshot = await ref.get();
  const data = snapshot.data();
  if (!snapshot.exists || !data) {
    console.error("Failed to get service topic data");
    return null;
  }
  const next = { ...data } as ServiceTopicData;
  if (url) next.url = url;
  if (label) next.label = label;
  if (timestamp) next.timestamp = timestamp;
  await ref.update(next);
  return {
    id: ref.id,
    data: next
  };
}

export async function deleteServiceTopic(
  ref: firebase.firestore.DocumentReference
): Promise<void> {
  await ref.delete();
}
