import dayjs from "dayjs";

export function getCurrentUnixtime(): number {
  return Math.floor(Date.now() / 1000);
}

export function convertToDateFromUnixtime(unixtime: number): string {
  return dayjs(unixtime * 1000)
    .locale("ja")
    .format("YYYY-MM-DD");
}

export function convertToDateFromUnixtimeSlash(unixtime: number): string {
  return dayjs(unixtime * 1000)
    .locale("ja")
    .format("YYYY/MM/DD");
}

export function convertToTimeFromUnixtime(unixtime: number): string {
  return dayjs(unixtime * 1000)
    .locale("ja")
    .format("HH:mm");
}

export function convertToTimeSecFromUnixtime(unixtime: number): string {
  return dayjs(unixtime * 1000)
    .locale("ja")
    .format("HH:mm:ss");
}

export function convertToUnixtimeFromDate(date: string): number {
  return dayjs(date).locale("ja").unix();
}

export function convertToUnixtimeFromDateAndTime(
  date: string,
  time: string
): number {
  return dayjs(`${date} ${time}`).locale("ja").unix();
}

// 引数で与えられた unixtime の 1日前の日本時間午前0時の unixtime を取得
export function getUnixtimeDayAgoFrom(unixtime: number): number {
  const time = dayjs(unixtime * 1000)
    .locale("ja")
    .add(-1, "day")
    .unix();
  const date = convertToDateFromUnixtime(time);
  return convertToUnixtimeFromDate(date);
}

// 引数で与えられた unixtime の 28日前の日本時間午前0時の unixtime を取得
export function getUnixtimeFourWeeksAgoFrom(unixtime: number): number {
  const time = dayjs(unixtime * 1000)
    .locale("ja")
    .add(-28, "day")
    .unix();
  const date = convertToDateFromUnixtime(time);
  return convertToUnixtimeFromDate(date);
}

// 引数で与えられた unixtime の 7日前の日本時間午前0時の unixtime を取得
export function getUnixtimeSevenDaysAgoFrom(unixtime: number): number {
  const time = dayjs(unixtime * 1000)
    .locale("ja")
    .add(-7, "day")
    .unix();
  const date = convertToDateFromUnixtime(time);
  return convertToUnixtimeFromDate(date);
}

export function getUnixtimeSevenDaysLaterFrom(unixtime: number): string {
  const time = dayjs(unixtime * 1000)
    .locale("ja")
    .add(7, "day")
    .unix();
  return convertToDateFromUnixtime(time);
}

export function getUnixtimeAnyDaysLaterFrom(
  unixtime: number,
  additionDate: number
): string {
  const time = dayjs(unixtime * 1000)
    .locale("ja")
    .add(additionDate, "day")
    .unix();
  return convertToDateFromUnixtime(time);
}

export function getDateOneDaysAgoFromUnixtime(unixtime: number): string {
  return dayjs(unixtime * 1000)
    .locale("ja")
    .add(-1, "day")
    .format("YYYY-MM-DD");
}

export function getWeekdayStr(weekdayNum: number) {
  if (weekdayNum === 0) {
    return "日";
  } else if (weekdayNum === 1) {
    return "月";
  } else if (weekdayNum === 2) {
    return "火";
  } else if (weekdayNum === 3) {
    return "水";
  } else if (weekdayNum === 4) {
    return "木";
  } else if (weekdayNum === 5) {
    return "金";
  } else if (weekdayNum === 6) {
    return "土";
  } else {
    return "";
  }
}
