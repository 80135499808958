
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["input", "enter"],
  props: {
    label: String,
    name: String,
    placeholder: String,
    value: String,
    height: String,
    backgroundColor: String
  }
})
export default class MTextArea extends Vue {
  label = "";
  name = "";
  placeholder = "";
  value = "";
  height = "normal";
  backgroundColor = "";

  get dynamicCss() {
    const css: { [name: string]: boolean } = {};
    if (!this.backgroundColor) {
      css["bg-primary-100"] = true;
    }
    if (this.backgroundColor === "white") {
      css["bg-white"] = true;
    }
    if (this.height === "tall") {
      css["tall"] = true;
    }
    return css;
  }
}
