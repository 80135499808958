// messageReservation関連view (MessageList) 用のvuex

import { saveErrorLog } from "@/api/error";
import {
  fetchMessageRegularlyReservations,
  fetchMessageReservations
} from "@/api/message_reservation";
import { MessageRegularlyReservation } from "@/entities/message_regularly_reservation";
import { MessageReservation } from "@/entities/message_reservation";
import { Module } from "vuex";
import { RootState } from "..";

interface MessageReservationState {
  initialized: boolean;
  load: boolean;
  messageReservations: MessageReservation[];
  messageRegularlyReservations: MessageRegularlyReservation[];
}

const messageReservationModule: Module<MessageReservationState, RootState> = {
  namespaced: true,

  state: {
    initialized: false,
    load: true,
    messageReservations: [],
    messageRegularlyReservations: []
  },

  getters: {},

  mutations: {
    INITIALIZE(state) {
      state.initialized = true;
    },
    SET_LOAD(state, load: boolean) {
      state.load = load;
    },
    SET_MESSAGE_RESERVATIONS(state, reservations: MessageReservation[]) {
      state.messageReservations = reservations;
    },
    SET_MESSAGE_REGULARLY_RESERVATIONS(
      state,
      reservations: MessageRegularlyReservation[]
    ) {
      state.messageRegularlyReservations = reservations;
    },
    CLEAR(state) {
      state.initialized = false;
      state.load = true;
      state.messageReservations = [];
      state.messageRegularlyReservations = [];
    }
  },

  actions: {
    async setup(context) {
      if (context.state.initialized) {
        return;
      }

      context.commit("INITIALIZE");
      await Promise.all([
        context.dispatch("getMessageReservations"),
        context.dispatch("getMessageRegularlyReservations")
      ]);
      context.commit("SET_LOAD", false);
    },
    async getMessageReservations(context) {
      const school = context.rootState.school;
      try {
        const reservations = await fetchMessageReservations(
          school ? school.ref.id : undefined
        );
        context.commit("SET_MESSAGE_RESERVATIONS", reservations);
      } catch (e) {
        alert(`メッセージ予約の取得に失敗しました\n\n${e}`);
        await saveErrorLog(
          context.rootState.role,
          e.code,
          e.message,
          "Failed to get message reservations"
        );
      }
    },
    async getMessageRegularlyReservations(context) {
      const school = context.rootState.school;
      try {
        const reservations = await fetchMessageRegularlyReservations(
          school ? school.ref.id : undefined
        );
        context.commit("SET_MESSAGE_REGULARLY_RESERVATIONS", reservations);
      } catch (e) {
        alert(`メッセージ予約の取得に失敗しました\n\n${e}`);
        await saveErrorLog(
          context.rootState.role,
          e.code,
          e.message,
          "Failed to get message regularly reservations"
        );
      }
    }
  }
};

export default messageReservationModule;
