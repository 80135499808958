import firebase from "firebase/app";

import { MessageType, MessageUser, MessageContent } from "./message";
import { MessageTo } from "./message_reservation";
import { schoolCollectionKey } from "./school";

export const messageRegularlyReservationCollectionKey =
  "messageRegularlyReservations";

export type MessageRegularlyReservation = {
  ref: firebase.firestore.DocumentReference;
  data: MessageRegularlyReservationData;
};

export type MessageRegularlyReservationData = {
  from: MessageUser;
  to: MessageTo;
  messageType: MessageType;
  messageText?: string;
  messageContents?: MessageContent[];
  nextTime: number; // 次回送信予定時間
  reservationType: "day" | "week" | "month";
  time: string; // 通知時間(HH:mm)
  weekday?: number; // 曜日(type == "week" の場合のみ) / 0 ~ 6
  monthday?: number; // 月内の日付(type == "month" の場合のみ)
  deletionTime?: number; // 削除予定時間
  toOnlyRoomUsers?: boolean;
};

// messageRegularlyReservation の [schoolDocId, reservationDocId] を返す
export function getDocIdsOfMessageRegularlyReservation(
  reservation: MessageRegularlyReservation
): [string, string] {
  const reservationRef = reservation.ref;
  if (
    !reservationRef.parent.parent ||
    reservationRef.parent.parent.parent.id !== schoolCollectionKey
  ) {
    return ["", reservationRef.id];
  }
  const schoolRef = reservationRef.parent.parent;
  return [schoolRef.id, reservationRef.id];
}

export function convertToMessageRegularlyReservation(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): MessageRegularlyReservation {
  const returnData: MessageRegularlyReservationData = {
    from: data.from,
    to: data.to,
    messageType: data.messageType,
    nextTime: data.nextTime,
    reservationType: data.reservationType,
    time: data.time
  };

  if (data.messageText) {
    returnData.messageText = data.messageText;
  }

  if (data.messageContents) {
    returnData.messageContents = data.messageContents;
  }

  if (data.weekday !== undefined) {
    returnData.weekday = data.weekday;
  }

  if (data.monthday !== undefined) {
    returnData.monthday = data.monthday;
  }

  if (data.deletionTime) {
    returnData.deletionTime = data.deletionTime;
  }

  if (!data.toOnlyRoomUsers) {
    returnData.toOnlyRoomUsers = false;
  } else {
    returnData.toOnlyRoomUsers = data.toOnlyRoomUsers;
  }

  return {
    ref,
    data: returnData
  };
}
