import { classroomCollectionKey } from "@/entities/classroom";
import {
  schoolCollectionKey,
  schoolConfigCollectionKey
} from "@/entities/school";
import { RoomSummaryWithRunningRegistrants } from "@/entities/room";
import { RunningTimer } from "@/entities/learning";
import {
  Student,
  StudentGrade,
  studentCollectionKey,
  StudentConfig,
  studentConfigCollectionKey,
  StudentSecret,
  StudentSecretData,
  StudentProperty,
  studentSecretCollectionKey,
  convertToStudent,
  convertToStudentConfig,
  convertToStudentSecret,
  elementarySchoolGrades,
  juniorHighSchoolGrades,
  highSchoolGrades
} from "@/entities/student";
import {
  deleteRegularlyReservation,
  deleteReservation,
  getRegularlyReservationsOf,
  getReservationsOf
} from "@/api/reservation";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { getSchoolConfig, getSchoolIdOfStudent } from "./school";
import { roleCollectionKey } from "@/entities/role";

export async function fetchStudentConfig(
  ref: firebase.firestore.DocumentReference
): Promise<StudentConfig | null> {
  const snapshot = await ref.get();
  const data = snapshot.data();
  if (!snapshot.exists || !data) {
    return null;
  }
  return convertToStudentConfig(data, snapshot.ref);
}

export async function fetchStudentSecrets(): Promise<StudentSecret[]> {
  const snapshot = await firebase
    .firestore()
    .collectionGroup(studentSecretCollectionKey)
    .get();
  if (snapshot.empty) {
    return [];
  }

  return snapshot.docs.map(doc => convertToStudentSecret(doc.data(), doc.ref));
}

export async function createOrUpdateStudentScret(
  ref: firebase.firestore.DocumentReference,
  data: StudentSecretData
): Promise<void> {
  const snapshot = await ref.get();
  if (!snapshot.exists) {
    await ref.set(data);
  } else {
    await ref.update(data);
  }
}

export async function getStudents(): Promise<Student[]> {
  const db = firebase.firestore();

  const studentsSnapshot = await db.collectionGroup(studentCollectionKey).get();
  if (studentsSnapshot.empty) {
    return [];
  }

  return studentsSnapshot.docs.map(doc =>
    convertToStudent(doc.data(), doc.ref)
  );
}

export async function getStudentsOfClassroom(
  classroom: firebase.firestore.DocumentReference
): Promise<Student[]> {
  const studentsSnapshot = await classroom
    .collection(studentCollectionKey)
    .get();
  if (studentsSnapshot.empty) {
    return [];
  }

  return studentsSnapshot.docs.map(doc =>
    convertToStudent(doc.data(), doc.ref)
  );
}

export async function getStudentList(
  start: number,
  end: number
): Promise<{ [key: string]: string | number | boolean }[]> {
  const callGetStudentList = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("get_student_list", { timeout: 540 * 1000 }); //生徒増加によるタイムアウトエラーを防ぐため
  const res = await callGetStudentList({ start, end });
  return res.data.datas;
}

export async function getStatusList({
  type,
  tutorDocId
}: {
  type: "admin" | "tutor";
  tutorDocId: string;
}): Promise<{
  timers: RunningTimer[];
  rooms: RoomSummaryWithRunningRegistrants[];
}> {
  const callGetStatusList = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("get_student_status_list", { timeout: 540 * 1000 }); //生徒増加によるタイムアウトエラーを防ぐため

  const { data } = await callGetStatusList({ type, tutorDocId });
  return data;
}

export type NewStudentData = {
  classroomRef: firebase.firestore.DocumentReference;
  id: string;
  loginId: string;
  name: string;
  grade: StudentGrade;
  classSchedules: string[];
  emailReceivers: string[];
  password: string;
  properties: StudentProperty[] | [];
  characteristic: string;
};

export async function registerStudent(student: NewStudentData): Promise<void> {
  const school = student.classroomRef.parent.parent!.id;
  const classroom = student.classroomRef.id;
  const callRegisterStudent = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("register_student", { timeout: 540 * 1000 });
  const res = await callRegisterStudent({
    school,
    classroom,
    student: {
      id: student.id,
      loginId: student.loginId,
      name: student.name,
      grade: student.grade,
      classSchedules: student.classSchedules,
      emailReceivers: student.emailReceivers,
      password: student.password,
      properties: student.properties,
      characteristic: student.characteristic
    }
  });
  if (!res.data.success) {
    if (res.data.errorMessage === "already-exists") {
      throw new Error(
        "同じスクール内に存在する生徒ID、もしくはすでに使われているログインIDです"
      );
    }
    throw new Error(res.data.errorMessage ?? "Unexpected error occurred");
  }
}

export async function transferStudent(
  studentRef: firebase.firestore.DocumentReference,
  newClassroomId: string
) {
  const classroomRef = studentRef.parent.parent!;
  const schoolRef = classroomRef.parent.parent!;
  const callTransferStudent = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("transfer_student");

  const res = await callTransferStudent({
    school: schoolRef.id,
    classroom: classroomRef.id,
    student: studentRef.id,
    newClassroom: newClassroomId
  });
  if (!res.data.success) {
    throw new Error("予期せぬエラーが発生しました");
  }
}

export async function deleteStudent(
  ref: firebase.firestore.DocumentReference
): Promise<void> {
  await ref.delete();
}

export async function updateStudent(
  ref: firebase.firestore.DocumentReference,
  id: string,
  name: string,
  grade: StudentGrade,
  classSchedules: string[],
  emailReceivers: string[],
  password: string,
  studentLoginId: string,
  properties: StudentProperty[],
  characteristic: string
): Promise<void> {
  const studentRoles = await firebase
    .firestore()
    .collection(roleCollectionKey)
    .where("type", "==", "student")
    .get();

  const existingRole = studentRoles.docs
    .filter(doc => doc.exists && doc.data() && doc.data().ref.id !== ref.id)
    .find(doc => doc.data().loginId === studentLoginId);
  if (existingRole && existingRole.exists) {
    // 生徒の実体が存在しないのにroleにデータが残っていた場合(on_delete_studentのエラーで発生事例あり)
    const snapshot = await (existingRole.data()
      .ref as firebase.firestore.DocumentReference).get();
    if (snapshot.exists && snapshot.ref.parent.id === studentCollectionKey) {
      throw new Error(`${studentLoginId}はすでに他の生徒に利用されています`);
    } else {
      // 実体がないのでroles,config,ユーザーを削除する必要あり(バックエンドにてon_update_student_login_idで処理)
      console.warn(
        "There may still be some information left for the deleted student."
      );
    }
  }

  const studentRole = studentRoles.docs.find(
    doc => doc.exists && doc.data() && doc.data().ref.id === ref.id
  );
  if (!studentRole) throw new Error("Cannot find student role");

  await Promise.all([
    ref.parent
      .parent!.collection(studentConfigCollectionKey)
      .doc(ref.id)
      .update({
        password,
        loginId: studentLoginId
      }),
    ref.update({
      id,
      name,
      grade,
      classSchedules,
      emailReceivers,
      properties,
      characteristic
    }),
    studentRole.ref.update({ loginId: studentLoginId })
  ]);
}

export async function updateStudentRecess(
  studentRef: firebase.firestore.DocumentReference,
  recessTime: number
) {
  const [reservations, regularlyReservations, studentData] = await Promise.all([
    getReservationsOf(studentRef),
    getRegularlyReservationsOf(studentRef),
    studentRef.get()
  ]);
  const student = convertToStudent(studentData.data()!, studentData.ref);
  await Promise.all([
    reservations.map(reservation => deleteReservation(reservation.ref)),
    regularlyReservations.map(regularlyReservation =>
      deleteRegularlyReservation(regularlyReservation.ref)
    )
  ]);
  if (student.data.isNandeUserCreated && recessTime !== 0) {
    const unlinkSchoolAi = firebase
      .app()
      .functions("asia-northeast1")
      .httpsCallable("unlink_school_ai");
    const requestPayload = {
      schoolDocId: student.ref.parent.parent!.parent.parent!.id,
      classroomDocId: student.ref.parent.parent!.id,
      studentDocId: student.ref.id,
      uidOfSchoolAI: student.data.nandeUserId
    };
    await unlinkSchoolAi({
      ...requestPayload
    });
  }
  return await studentRef.update({ recessTime });
}
const allGrades = [
  ...elementarySchoolGrades,
  ...juniorHighSchoolGrades,
  ...highSchoolGrades
] as StudentGrade[];

// 学年をシフトする関数を作成する
function generateGradeShifter(
  operand: number
): (grade: StudentGrade) => StudentGrade {
  return grade => {
    const index = allGrades.indexOf(grade);
    if (
      index === -1 ||
      index + operand < 0 ||
      index + operand > allGrades.length - 1
    )
      return allGrades[operand > 0 ? allGrades.length - 1 : 0];
    return allGrades[index + operand];
  };
}

function convertToStudentRef(
  db: firebase.firestore.Firestore,
  schoolId: string,
  classroomId: string,
  studentId: string
): firebase.firestore.DocumentReference {
  return db
    .collection(schoolCollectionKey)
    .doc(schoolId)
    .collection(classroomCollectionKey)
    .doc(classroomId)
    .collection(studentCollectionKey)
    .doc(studentId);
}

function transformStudentRefFromStudent(
  db: firebase.firestore.Firestore,
  student: Student
): firebase.firestore.DocumentReference {
  const classroomRef = student.ref.parent.parent!;
  const schoolRef = classroomRef.parent.parent!;
  return convertToStudentRef(db, schoolRef.id, classroomRef.id, student.ref.id);
}

export async function moveUpGradeOfStudents(students: Student[]) {
  const db = firebase.firestore();
  const batch = db.batch();
  const operate = generateGradeShifter(1);
  students.forEach(student => {
    batch.update(transformStudentRefFromStudent(db, student), {
      grade: operate(student.data.grade)
    });
  });
  await batch.commit();
}

export async function moveDownGradeOfStudents(students: Student[]) {
  const db = firebase.firestore();
  const batch = db.batch();
  const operate = generateGradeShifter(-1);
  students.forEach(student => {
    batch.update(transformStudentRefFromStudent(db, student), {
      grade: operate(student.data.grade)
    });
  });
  await batch.commit();
}

export function getStudentsBasedOnRole(
  isServiceProvider: boolean,
  studentsInStore: Student[],
  mySchoolDocId: string
): Student[] {
  if (!studentsInStore) {
    throw new Error("Store内に生徒データが見つかりません");
  }
  if (!mySchoolDocId) {
    throw new Error("現在ログインしているスクールのIDが見つかりません");
  }

  if (!isServiceProvider) return studentsInStore;

  return studentsInStore.filter(student => {
    const studentRef = student.ref;
    const path = studentRef.path;
    const pathSegments = path.split("/");
    const schoolId =
      pathSegments[pathSegments.indexOf(schoolCollectionKey) + 1];
    return schoolId === mySchoolDocId;
  });
}

export async function selectStudentsByRoomUsage(
  students: Student[]
): Promise<Student[]> {
  const db = firebase.firestore();

  const schoolConfigPromises = students.map(async student => {
    const schoolId = getSchoolIdOfStudent(student);
    const schoolRef = db.collection(schoolConfigCollectionKey).doc(schoolId);
    const schoolConfig = await getSchoolConfig(schoolRef);
    return { student, schoolConfig };
  });

  const results = await Promise.all(schoolConfigPromises);

  const filteredStudents = results
    .filter(({ schoolConfig }) => {
      return schoolConfig && !schoolConfig.data.useSchoolAiExclusively;
    })
    .map(({ student }) => student);

  return filteredStudents;
}
