import { classroomCollectionKey } from "@/entities/classroom";
import {
  convertToMessage,
  Message,
  messageCollectionKey,
  MessageData,
  MessageUser,
  MessageContent
} from "@/entities/message";
import {
  convertToMessageTemplate,
  MessageTemplate,
  messageTemplateCollectionKey,
  MessageTemplateData
} from "@/entities/message_template";
import { schoolCollectionKey } from "@/entities/school";
import { studentCollectionKey } from "@/entities/student";
import firebase from "firebase/app";
import "firebase/firestore";

const maxBatchCount = 500;

export function getMessageStream(
  studentRef: firebase.firestore.DocumentReference,
  limit = 0
): firebase.firestore.Query {
  let query = studentRef
    .collection(messageCollectionKey)
    .orderBy("timestamp", "desc");
  if (limit > 0) {
    query = query.limit(limit);
  }
  return query;
}

export async function fetchMessages(
  studentRef: firebase.firestore.DocumentReference,
  limit = 0,
  offsetRef?: firebase.firestore.DocumentReference
): Promise<Message[]> {
  let query = studentRef
    .collection(messageCollectionKey)
    .orderBy("timestamp", "desc");
  if (limit > 0) {
    query = query.limit(limit);
  }
  if (offsetRef) {
    const offsetSnapshot = await offsetRef.get();
    query = query.startAfter(offsetSnapshot);
  }

  const messagesSnapshot = await query.get();
  if (messagesSnapshot.empty) {
    return [];
  }

  const messages = messagesSnapshot.docs.filter(
    doc => doc.data().type !== "multiple"
  );
  const multipleMessages = messagesSnapshot.docs.filter(
    doc => doc.data().type === "multiple"
  );

  return [
    ...messages.map(m => {
      const data = m.data();
      return convertToMessage(data, m.ref);
    }),
    ...multipleMessages
      .map(m => {
        const data = m.data();
        return data.messageContents.map((c: MessageContent) => {
          return convertToMessage(
            {
              ...data,
              messageText: c.text,
              type: c.type
            },
            m.ref
          );
        });
      })
      .flat()
  ];
}

export async function sendTextMessage(
  studentRef: firebase.firestore.DocumentReference,
  schoolName: string,
  from: MessageUser,
  messageText: string
): Promise<void> {
  let text: string;
  if (from.type === "admin") {
    from.name += "@管理人";
    text = "みんがく管理人:\n" + messageText;
  } else {
    text = schoolName + " " + from.name + "先生:\n" + messageText;
  }

  const message: MessageData = {
    from,
    type: "text",
    messageText: text,
    lineId: "",
    timestamp: Math.floor(Date.now() / 1000),
    checked: false
  };

  await studentRef.collection(messageCollectionKey).add(message);
}

export async function sendButtonTemplateMessage({
  data,
  option
}: {
  data: {
    schoolDocId: string;
    classroomDocId: string;
    studentDocId: string;
    name: string;
    from: MessageUser;
    messageText: string;
    templateMessage: {
      altText: string;
      buttonLabel: string;
      buttonUriPath: string; // バックエンドで liff のドメインを補足する。ここではルート相対パスを指定する
      title: string;
    };
  };
  option?: {
    useReservation: boolean;
    reservationTime: number;
  };
}): Promise<void> {
  const req = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("create_button_template_message_for_student");

  const res = await req({
    data,
    option: option ?? {}
  });
  if (res.data.error) {
    throw new Error(res.data.error);
  }
}

export async function sendCreateReservationBotMessage(
  studentRef: firebase.firestore.DocumentReference,
  fromName: string,
  reservationTimeText: string
): Promise<void> {
  const data: MessageData = {
    from: {
      type: "bot",
      userId: "",
      name: fromName
    },
    type: "text",
    messageText: `${fromName} が学習予定を作成しました\n\n予定日時: ${reservationTimeText}`,
    lineId: "",
    timestamp: Math.floor(Date.now() / 1000),
    checked: false
  };
  await studentRef.collection(messageCollectionKey).add(data);
}

export async function sendCreateRegularyReservationBotMessage(
  studentRef: firebase.firestore.DocumentReference,
  fromName: string
): Promise<void> {
  const data: MessageData = {
    from: {
      type: "bot",
      userId: "",
      name: fromName
    },
    type: "text",
    messageText: `${fromName} が繰り返し学習予定を作成しました`,
    lineId: "",
    timestamp: Math.floor(Date.now() / 1000),
    checked: false
  };
  await studentRef.collection(messageCollectionKey).add(data);
}

export async function sendTextMessageToStudents(
  studentRefs: firebase.firestore.DocumentReference[],
  schoolName: string,
  from: MessageUser,
  messageText: string
): Promise<void> {
  let text: string;
  if (from.type === "admin") {
    from.name += "@管理人";
    text = "みんがく管理人:\n" + messageText;
  } else {
    text = schoolName + " " + from.name + "先生:\n" + messageText;
  }
  const message: MessageData = {
    from,
    type: "text",
    messageText: text,
    lineId: "",
    timestamp: Math.floor(Date.now() / 1000),
    checked: false
  };

  const db = firebase.firestore();
  const batchArray: firebase.firestore.WriteBatch[] = [db.batch()];
  let operationCounter = 0;
  let batchIndex = 0;
  studentRefs.forEach(ref => {
    const classroomRef = ref.parent.parent!;
    const schoolRef = classroomRef.parent.parent!;
    const sameDbStudentRef = db
      .collection(schoolCollectionKey)
      .doc(schoolRef.id)
      .collection(classroomCollectionKey)
      .doc(classroomRef.id)
      .collection(studentCollectionKey)
      .doc(ref.id);
    const newRef = sameDbStudentRef.collection(messageCollectionKey).doc();
    batchArray[batchIndex].set(newRef, message);
    operationCounter++;
    if (operationCounter === maxBatchCount - 1) {
      batchArray.push(db.batch());
      batchIndex++;
      operationCounter = 0;
    }
  });
  for (const batch of batchArray) {
    await batch.commit();
  }
  return;
}

export async function sendMultipleMessagesToStudents(
  studentRefs: firebase.firestore.DocumentReference[],
  schoolName: string,
  from: MessageUser,
  messageContents: MessageContent[]
): Promise<void> {
  let fromName: string;
  if (from.type === "admin") {
    fromName = "みんがく管理人:\n";
  } else {
    fromName = schoolName + " " + from.name + "先生:\n";
  }
  messageContents.map(m => {
    if (m.type === "text") {
      m.text = fromName + m.text;
    }
    return m;
  });
  const message: MessageData = {
    from,
    type: "multiple",
    messageContents,
    lineId: "",
    timestamp: Math.floor(Date.now() / 1000),
    checked: false
  };

  const db = firebase.firestore();
  const batchArray: firebase.firestore.WriteBatch[] = [db.batch()];
  let operationCounter = 0;
  let batchIndex = 0;
  studentRefs.forEach(ref => {
    const classroomRef = ref.parent.parent!;
    const schoolRef = classroomRef.parent.parent!;
    const sameDbStudentRef = db
      .collection(schoolCollectionKey)
      .doc(schoolRef.id)
      .collection(classroomCollectionKey)
      .doc(classroomRef.id)
      .collection(studentCollectionKey)
      .doc(ref.id);
    const newRef = sameDbStudentRef.collection(messageCollectionKey).doc();
    batchArray[batchIndex].set(newRef, message);
    operationCounter++;
    if (operationCounter === maxBatchCount - 1) {
      batchArray.push(db.batch());
      batchIndex++;
      operationCounter = 0;
    }
  });
  for (const batch of batchArray) {
    await batch.commit();
  }
  return;
}

export async function sendImageMessage(
  studentRef: firebase.firestore.DocumentReference,
  from: MessageUser,
  imagePath: string
): Promise<void> {
  if (from.type === "admin") {
    from.name += "@管理人";
  }

  const message: MessageData = {
    from,
    type: "image",
    messageText: imagePath,
    lineId: "",
    timestamp: Math.floor(Date.now() / 1000),
    checked: false
  };

  await studentRef.collection(messageCollectionKey).add(message);
}

export async function sendImageMessageToStudents(
  studentRefs: firebase.firestore.DocumentReference[],
  from: MessageUser,
  imagePath: string
): Promise<void> {
  if (from.type === "admin") {
    from.name += "@管理人";
  }

  const message: MessageData = {
    from,
    type: "image",
    messageText: imagePath,
    lineId: "",
    timestamp: Math.floor(Date.now() / 1000),
    checked: false
  };

  const db = firebase.firestore();
  const batchArray: firebase.firestore.WriteBatch[] = [db.batch()];
  let operationCounter = 0;
  let batchIndex = 0;
  studentRefs.forEach(ref => {
    const classroomRef = ref.parent.parent!;
    const schoolRef = classroomRef.parent.parent!;
    const sameDbStudentRef = db
      .collection(schoolCollectionKey)
      .doc(schoolRef.id)
      .collection(classroomCollectionKey)
      .doc(classroomRef.id)
      .collection(studentCollectionKey)
      .doc(ref.id);
    const newRef = sameDbStudentRef.collection(messageCollectionKey).doc();
    batchArray[batchIndex].set(newRef, message);
    operationCounter++;
    if (operationCounter === maxBatchCount - 1) {
      batchArray.push(db.batch());
      batchIndex++;
      operationCounter = 0;
    }
  });

  for (const batch of batchArray) {
    await batch.commit();
  }
  return;
}

export async function registerMessageTemplate(
  parentRef: firebase.firestore.DocumentReference,
  heading: string,
  content: string
): Promise<void> {
  const data: MessageTemplateData = {
    heading,
    content,
    usedCount: 0
  };
  await parentRef.collection(messageTemplateCollectionKey).add(data);
}

export async function fetchCommonMessageTemplates(): Promise<
  MessageTemplate[]
> {
  const snapshot = await firebase
    .firestore()
    .collection(messageTemplateCollectionKey)
    .orderBy("usedCount", "desc")
    .get();

  if (snapshot.empty) {
    return [];
  }

  return snapshot.docs.map(doc =>
    convertToMessageTemplate(doc.data(), doc.ref)
  );
}

export async function fetchMessageTemplatesOf(
  parentRef: firebase.firestore.DocumentReference
): Promise<MessageTemplate[]> {
  const snapshot = await parentRef
    .collection(messageTemplateCollectionKey)
    .orderBy("usedCount", "desc")
    .get();

  if (snapshot.empty) {
    return [];
  }

  return snapshot.docs.map(doc =>
    convertToMessageTemplate(doc.data(), doc.ref)
  );
}

export async function updateMessageTemplate(
  ref: firebase.firestore.DocumentReference,
  data: {
    content: string;
    heading: string;
  }
): Promise<void> {
  await ref.update(data);
}

export async function deleteMessageTemplate(
  ref: firebase.firestore.DocumentReference
): Promise<void> {
  await ref.delete();
}
