import firebase from "firebase/app";

export const learningCollectionKey = "learnings";

export const subjectNames = [
  "算数",
  "数学",
  "国語",
  "英語",
  "理科",
  "物理",
  "化学",
  "生物",
  "地学",
  "社会",
  "日本史",
  "世界史",
  "地理",
  "現社",
  "倫理",
  "政経",
  "音楽",
  "美術",
  "保体",
  "技術",
  "家庭",
  "情報",
  "その他",
  "国語（こくご）",
  "算数（さんすう）",
  "理科（りか）",
  "社会（しゃかい）",
  "英語（えいご）",
  "それ以外（いがい）"
] as const;

// 参考
// https://github.com/microsoft/TypeScript/issues/28046#issuecomment-480516434
type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never;

export type SubjectName = ElementType<typeof subjectNames>;

export const popySubjects: SubjectName[] = [
  "国語（こくご）",
  "算数（さんすう）",
  "理科（りか）",
  "社会（しゃかい）",
  "英語（えいご）",
  "それ以外（いがい）"
];

export const elementarySubjects: SubjectName[] = [
  "算数",
  "国語",
  "英語",
  "理科",
  "社会"
];
export const juniorSubjects: SubjectName[] = [
  "数学",
  "国語",
  "英語",
  "理科",
  "社会"
];
export const highNormalSubjects: SubjectName[] = ["英語", "数学", "国語"];
export const highScienceSubjects: SubjectName[] = [
  "物理",
  "化学",
  "生物",
  "地学"
];
export const highSocialSubjects: SubjectName[] = [
  "日本史",
  "世界史",
  "地理",
  "現社",
  "倫理",
  "政経"
];
export const otherSubjects: SubjectName[] = [
  "音楽",
  "美術",
  "保体",
  "技術",
  "家庭",
  "情報",
  "その他"
];

export type Learning = {
  ref: firebase.firestore.DocumentReference;
  data: LearningData;
};

/*
  生徒側画面から learning の uid だけ渡して
  タイムキーパーに対応する learningData を更新できるように、
  uid をドキュメントのフィールドにする。
  これによって .collectionGroup().where() で uid からドキュメントが検索できる。

  この際、uid フィールドに以下の画面で確認できる自動インデックス設定の除外が必要だった。
  https://console.firebase.google.com/u/0/project/mingaku-jp-dev/firestore/indexes/single-field/manage
  
  以下のような手順で設定。
  - .collectionGroup(learningCollectionKey).where("uid","==",learningDocId) を行うメソッドを実行
  - エラーログにインデックスの設定が必要な旨が出力される
  - エラーログのリンクに遷移、自動的にインデックス設定が更新される
*/
export type LearningData = {
  uid?: string;
  school: string;
  classroom: string;
  student: string;
  subjects: LearningSubject[];
  records: LearningRecord[];
  goal: string;
  reflection?: LearningReflection;
  timestamp: number;
  totalTime: number;
  day: string;
  meta: LearningMeta;
  videoFilePath?: string;
  manuallyUpdated?: boolean;
};

export type LearningSubject = {
  name: SubjectName;
  ratio: number;
};

export type LearningRecord = {
  start: number;
  end: number;
};

export type LearningReflection = {
  achievementRate: number;
  additionalContent: string;
  images: string[];
  concentration: number;
  complaint: number;
  thoughts: string;
  other: string;
};

export type LearningType = "room" | "timer";

export type LearningMeta = {
  type: LearningType;
  labels: firebase.firestore.DocumentReference[];
  timer?: {
    start: number; // 最新の開始（再開）時間
    end: number; // 学習終了予定時間
    toCloseForciblyAfter?: number; // 強制学習記録時間
    tracks: LearningRecord[]; // 1回の開始（再開） 〜 一時停止の組
    lastDisplayed: number; // 最終タイムキーパー画面表示時間
  };
};

export type RunningTimer = {
  id: string;
  start: number;
  studentDocId: string;
};

export function getStudentRefFromLearningRef(
  learningRef: firebase.firestore.DocumentReference
): firebase.firestore.DocumentReference {
  if (learningRef.parent.id !== learningCollectionKey) {
    throw new Error("given data is not learning reference");
  }

  if (!learningRef.parent.parent) {
    throw new Error("unexpected error occured");
  }

  return learningRef.parent.parent;
}

// student の [schoolDocId, classroomDocId, studentDocId, learningDocId] を返す
export function getDocIdsOfLearningRef(
  ref: firebase.firestore.DocumentReference
): [string, string, string, string] {
  if (!ref.parent.parent) {
    return ["", "", "", ""];
  }
  const studentRef = ref.parent.parent;
  if (!studentRef.parent.parent) {
    return ["", "", "", ""];
  }
  const classroomRef = studentRef.parent.parent;
  if (!classroomRef.parent.parent) {
    return ["", "", "", ""];
  }
  const schoolRef = classroomRef.parent.parent;
  return [schoolRef.id, classroomRef.id, studentRef.id, ref.id];
}

export function convertToLearning(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Learning {
  const returnData: LearningData = {
    school: data.school,
    classroom: data.classroom,
    student: data.student,
    subjects: data.subjects,
    records: data.records,
    goal: data.goal,
    timestamp: data.timestamp,
    totalTime: data.totalTime,
    day: data.day,
    meta: data.meta ?? null // undefined対策  if (data.meta) {returnData.meta = data.meta;}で対応する方法もあるが、修正の影響範囲が大きいため、左記のように対応(やりとりスレッド: https://w1610375070-0eg687089.slack.com/archives/C01JBCZ8ZU5/p1646660511929379)
  };

  if (data.reflection) {
    returnData.reflection = data.reflection;
  }
  if (data.videoFilePath) {
    returnData.videoFilePath = data.videoFilePath;
  }
  if (
    data.manuallyUpdated !== undefined &&
    typeof data.manuallyUpdated === "boolean"
  ) {
    returnData.manuallyUpdated = data.manuallyUpdated;
  } else {
    returnData.manuallyUpdated = false;
  }

  return {
    ref,
    data: returnData
  };
}

export function getPausedSeconds(
  record: LearningRecord[],
  latestStartTime: number
): number {
  const reversedRecords = record.sort((a, b) => (a.end > b.end ? -1 : 1));
  const pausedSeconds = reversedRecords.reduce(
    ([accumlated, startTime], { end, start }) => [
      accumlated + (startTime - end),
      start
    ],
    [0, latestStartTime]
  )[0];
  return pausedSeconds;
}

export function getStudyingSecondsByRecord(record: LearningRecord[]): number {
  return record.reduce((pre, current) => pre + current.end - current.start, 0);
}

export const colorsForSubjects: {
  [key: string]: { border: string; color: string };
} = {
  算数: {
    border: "#3B82F6",
    color: "rgba(59, 130, 246, 0.5)"
  },
  数学: {
    border: "#3B82F6",
    color: "rgba(59, 130, 246, 0.5)"
  },
  国語: {
    border: "#EF4444",
    color: "rgba(239, 68, 68, 0.5)"
  },
  英語: {
    border: "#EC4899",
    color: "rgba(236, 72, 153, 0.5)"
  },
  理科: {
    border: "#10B981",
    color: "rgba(16, 185, 129, 0.5)"
  },
  物理: {
    border: "#10B981",
    color: "rgba(16, 185, 129, 0.5)"
  },
  化学: {
    border: "#10B981",
    color: "rgba(16, 185, 129, 0.5)"
  },
  生物: {
    border: "#10B981",
    color: "rgba(16, 185, 129, 0.5)"
  },
  地学: {
    border: "#10B981",
    color: "rgba(16, 185, 129, 0.5)"
  },
  社会: {
    border: "#D97706",
    color: "rgba(217, 119, 6, 0.5)"
  },
  日本史: {
    border: "#D97706",
    color: "rgba(217, 119, 6, 0.5)"
  },
  世界史: {
    border: "#D97706",
    color: "rgba(217, 119, 6, 0.5)"
  },
  地理: {
    border: "#D97706",
    color: "rgba(217, 119, 6, 0.5)"
  },
  現社: {
    border: "#D97706",
    color: "rgba(217, 119, 6, 0.5)"
  },
  倫理: {
    border: "#D97706",
    color: "rgba(217, 119, 6, 0.5)"
  },
  政経: {
    border: "#D97706",
    color: "rgba(217, 119, 6, 0.5)"
  },
  音楽: {
    border: "#6B7280",
    color: "rgba(107, 114, 128, 0.5)"
  },
  美術: {
    border: "#6B7280",
    color: "rgba(107, 114, 128, 0.5)"
  },
  保体: {
    border: "#6B7280",
    color: "rgba(107, 114, 128, 0.5)"
  },
  技術: {
    border: "#6B7280",
    color: "rgba(107, 114, 128, 0.5)"
  },
  家庭: {
    border: "#6B7280",
    color: "rgba(107, 114, 128, 0.5)"
  },
  情報: {
    border: "#6B7280",
    color: "rgba(107, 114, 128, 0.5)"
  },
  その他: {
    border: "#6B7280",
    color: "rgba(107, 114, 128, 0.5)"
  },
  "国語（こくご）": {
    border: "#EF4444",
    color: "rgba(239, 68, 68, 0.5)"
  },
  "算数（さんすう）": {
    border: "#3B82F6",
    color: "rgba(59, 130, 246, 0.5)"
  },
  "理科（りか）": {
    border: "#10B981",
    color: "rgba(16, 185, 129, 0.5)"
  },
  "社会（しゃかい）": {
    border: "#D97706",
    color: "rgba(217, 119, 6, 0.5)"
  },
  "英語（えいご）": {
    border: "#EC4899",
    color: "rgba(236, 72, 153, 0.5)"
  },
  "それ以外（いがい）": {
    border: "#6B7280",
    color: "rgba(107, 114, 128, 0.5)"
  }
};
