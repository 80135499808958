import firebase from "firebase/app";

export const regularlyReservationCollectionKey = "regularlyReservations";

export type RegularlyReservation = {
  ref: firebase.firestore.DocumentReference;
  data: RegularlyReservationData;
};

export type RegularlyReservationData = {
  beforeMinute: number; // 通知時間から実際の予約時間までの間
  nextTime: number;
  type: "day" | "week" | "month";
  time: string; // 通知時間
  weekday?: number; // 曜日(type == "week" の場合のみ) / 0 ~ 6
  monthday?: number; // 月内の日付(type == "month" の場合のみ)
  deletionTime?: number; // 削除予定時間
};

// regularly reservation の [schoolDocId, classroomDocId, studentDocId, regularlyReservaiontDocId] を返す
export function getDocIdsOfRegularlyReservation(
  reservation: RegularlyReservation
): [string, string, string, string] {
  const regularlyReservationRef = reservation.ref;
  if (!regularlyReservationRef.parent.parent) {
    return ["", "", "", ""];
  }
  const studentRef = regularlyReservationRef.parent.parent;
  if (!studentRef.parent.parent) {
    return ["", "", "", ""];
  }
  const classroomRef = studentRef.parent.parent;
  if (!classroomRef.parent.parent) {
    return ["", "", "", ""];
  }
  const schoolRef = classroomRef.parent.parent;
  return [
    schoolRef.id,
    classroomRef.id,
    studentRef.id,
    regularlyReservationRef.id
  ];
}

export function convertToRegularlyReservation(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): RegularlyReservation {
  const returnData: RegularlyReservationData = {
    beforeMinute: data.beforeMinute,
    nextTime: data.nextTime,
    type: data.type,
    time: data.time
  };
  if (data.weekday) {
    returnData.weekday = data.weekday;
  } else if (data.weekday === 0) {
    returnData.weekday = 0;
  }
  if (data.monthday) {
    returnData.monthday = data.monthday;
  }

  return {
    ref,
    data: returnData
  };
}
