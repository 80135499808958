import {
  convertToMessageEditor,
  MessageEditor,
  messageEditorCollectionKey,
  MessageEditorData
} from "@/entities/message_editor";
import firebase from "firebase/app";
import "firebase/firestore";

export function getMessageEditorStream(
  studentRef: firebase.firestore.DocumentReference,
  timestamp: number
): firebase.firestore.Query {
  return studentRef
    .collection(messageEditorCollectionKey)
    .where("timestamp", ">=", timestamp);
}

async function getMessageEditor(
  studentRef: firebase.firestore.DocumentReference,
  user: firebase.firestore.DocumentReference
): Promise<MessageEditor | null> {
  const res = await studentRef
    .collection(messageEditorCollectionKey)
    .where("user", "==", user)
    .limit(1)
    .get();
  if (res.empty) {
    return null;
  }
  return res.docs.map(doc => convertToMessageEditor(doc.data(), doc.ref))[0];
}

async function createMessageEditor(
  studentRef: firebase.firestore.DocumentReference,
  user: firebase.firestore.DocumentReference
): Promise<void> {
  const messageEditorData: MessageEditorData = {
    user,
    timestamp: Math.floor(Date.now() / 1000)
  };

  await studentRef
    .collection(messageEditorCollectionKey)
    .add(messageEditorData);
}

async function updateMessageEditor(
  ref: firebase.firestore.DocumentReference,
  data: MessageEditorData
): Promise<void> {
  await ref.update(data);
}

export async function updateMessageEditorInfo(
  studentRef: firebase.firestore.DocumentReference,
  user: firebase.firestore.DocumentReference
): Promise<void> {
  const messageEditor = await getMessageEditor(studentRef, user);
  if (!messageEditor) {
    await createMessageEditor(studentRef, user);
  } else {
    const newMessageEditorData: MessageEditorData = {
      user: messageEditor.data.user,
      timestamp: Math.floor(Date.now() / 1000)
    };
    await updateMessageEditor(messageEditor.ref, newMessageEditorData);
  }
}
