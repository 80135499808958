import firebase from "firebase/app";

export const registrantCollectionKey = "registrants";

export type Registrant = {
  ref: firebase.firestore.DocumentReference;
  data: RegistrantData;
};

export type RegistrantCameraStatus =
  | "use_device"
  | "short_break"
  | "connection_practice"
  | "device_defect"
  | "other";

export type RegistrantData = {
  student: firebase.firestore.DocumentReference;
  learning: firebase.firestore.DocumentReference;
  username: string;
  meetingId: string;
  registrantId: string;
  joinUrl: string;
  starts: number[];
  ends: number[];
  cameraStatus?: RegistrantCameraStatus;
  cameraComment?: string;
  greeting: string;
};

export function convertToRegistrant(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Registrant {
  const returnData: RegistrantData = {
    student: data.student,
    learning: data.learning,
    username: data.username,
    meetingId: data.meetingId,
    registrantId: data.registrantId,
    joinUrl: data.joinUrl,
    starts: data.starts,
    ends: data.ends,
    greeting: data.greeting
  };

  if (data.cameraStatus) {
    returnData.cameraStatus = data.cameraStatus;
  }

  if (data.cameraComment) {
    returnData.cameraComment = data.cameraComment;
  }

  return {
    ref,
    data: returnData
  };
}

// registrant の [roomDocId, registrantDocId] を返す
export function getDocIdsOfRegistrant(
  registrant: Registrant
): [string, string] {
  const registrantRef = registrant.ref;
  if (!registrantRef.parent.parent) {
    return ["", ""];
  }
  const roomRef = registrantRef.parent.parent;
  return [roomRef.id, registrantRef.id];
}
