// StickerSheet 用のvuex

import { saveErrorLog } from "@/api/error";
import { fetchStickers } from "@/api/sticker";
import { Sticker } from "@/entities/sticker";
import { Module } from "vuex";
import { RootState } from "..";

interface StickerSheetState {
  initialized: boolean;
  load: boolean;
  stickers: Sticker[];
}

const stickerSheetModule: Module<StickerSheetState, RootState> = {
  namespaced: true,

  state: {
    initialized: false,
    load: true,
    stickers: []
  },

  getters: {},

  mutations: {
    INITIALIZE(state) {
      state.initialized = true;
    },
    SET_LOAD(state, load: boolean) {
      state.load = load;
    },
    SET_STICKERS(state, stickers: Sticker[]) {
      state.stickers = stickers;
    },
    CLEAR(state) {
      state.initialized = false;
      state.load = true;
      state.stickers = [];
    }
  },

  actions: {
    async setup(context) {
      if (context.state.initialized) {
        return;
      }

      context.commit("INITIALIZE");
      await context.dispatch("getStickers");
      context.commit("SET_LOAD", false);
    },
    async getStickers(context) {
      try {
        const stickers = await fetchStickers();
        context.commit("SET_STICKERS", stickers);
      } catch (e) {
        alert(`スタンプ情報の取得に失敗しました\n\n${e}`);
        await saveErrorLog(
          context.rootState.role,
          e.code,
          e.message,
          "Failed to get stickers"
        );
      }
    }
  }
};

export default stickerSheetModule;
