import {
  convertToSticker,
  Sticker,
  stickerCollectionKey
} from "@/entities/sticker";
import firebase from "firebase/app";

export async function fetchStickers(): Promise<Sticker[]> {
  const db = firebase.firestore();
  const snapshot = await db
    .collection(stickerCollectionKey)
    .orderBy("order", "asc")
    .get();
  if (snapshot.empty) {
    return [];
  }

  return snapshot.docs.map(doc => convertToSticker(doc.data(), doc.ref));
}
