import {
  convertToRoomRegularlyReservation,
  RoomRegularlyReservation,
  roomRegularlyReservationCollectionKey,
  RoomRegularlyReservationData
} from "@/entities/room_regularly_reservation";
import {
  convertToRoomReservation,
  RoomReservation,
  roomReservationCollectionKey,
  RoomReservationData
} from "@/entities/room_reservation";
import firebase from "firebase/app";
import "firebase/firestore";

export async function createRoomReservation(
  name: string,
  groupName: string,
  withNotification: boolean,
  reservationTime: number,
  labels: firebase.firestore.DocumentReference[],
  end: number | null,
  group?: firebase.firestore.DocumentReference
): Promise<void> {
  const data: RoomReservationData = {
    name,
    groupName,
    withNotification,
    reservationTime,
    labels,
    end
  };
  if (group) {
    data.group = group;
  }

  await firebase.firestore().collection(roomReservationCollectionKey).add(data);
}

export async function createRoomRegularlyReservation(
  name: string,
  groupName: string,
  withNotification: boolean,
  nextTime: number,
  reservationType: "day" | "week" | "month",
  time: string,
  labels: firebase.firestore.DocumentReference[],
  end: string,
  weekday?: number,
  monthday?: number,
  deletionTime?: number,
  group?: firebase.firestore.DocumentReference
): Promise<void> {
  const data: RoomRegularlyReservationData = {
    name,
    groupName,
    withNotification,
    nextTime,
    reservationType,
    labels,
    end,
    time
  };
  if (reservationType === "month" && monthday !== undefined) {
    data.monthday = monthday;
  } else if (reservationType === "week" && weekday !== undefined) {
    data.weekday = weekday;
  }

  if (deletionTime) {
    data.deletionTime = deletionTime;
  }

  if (group) {
    data.group = group;
  }

  await firebase
    .firestore()
    .collection(roomRegularlyReservationCollectionKey)
    .add(data);
}

export async function fetchRoomReservations(): Promise<RoomReservation[]> {
  const snapshot = await firebase
    .firestore()
    .collection(roomReservationCollectionKey)
    .get();

  if (snapshot.empty) {
    return [];
  }

  return snapshot.docs.map(doc =>
    convertToRoomReservation(doc.data(), doc.ref)
  );
}

export async function fetchRoomRegularlyReservations(): Promise<
  RoomRegularlyReservation[]
> {
  const snapshot = await firebase
    .firestore()
    .collection(roomRegularlyReservationCollectionKey)
    .get();

  if (snapshot.empty) {
    return [];
  }

  return snapshot.docs.map(doc =>
    convertToRoomRegularlyReservation(doc.data(), doc.ref)
  );
}

export async function updateRoomReservation(
  ref: firebase.firestore.DocumentReference,
  name: string,
  groupName: string,
  withNotification: boolean,
  reservationTime: number,
  end: number | null,
  labels: firebase.firestore.DocumentReference[],
  group?: firebase.firestore.DocumentReference
): Promise<void> {
  const data: RoomReservationData = {
    name,
    groupName,
    withNotification,
    reservationTime,
    end,
    labels
  };
  if (group) {
    data.group = group;
  }

  await ref.update(data);
}

export async function updateRoomRegularlyReservation(
  ref: firebase.firestore.DocumentReference,
  name: string,
  groupName: string,
  withNotification: boolean,
  nextTime: number,
  reservationType: "day" | "week" | "month",
  time: string,
  end: string,
  labels: firebase.firestore.DocumentReference[],
  weekday?: number,
  monthday?: number,
  deletionTime?: number,
  group?: firebase.firestore.DocumentReference
): Promise<void> {
  const data: RoomRegularlyReservationData = {
    name,
    groupName,
    withNotification,
    nextTime,
    reservationType,
    time,
    end,
    labels
  };
  if (reservationType === "month" && monthday !== undefined) {
    data.monthday = monthday;
  } else if (reservationType === "week" && weekday !== undefined) {
    data.weekday = weekday;
  }

  if (deletionTime) {
    data.deletionTime = deletionTime;
  }

  if (group) {
    data.group = group;
  }

  await ref.update(data);
}

export async function deleteRoomReservation(
  ref: firebase.firestore.DocumentReference
): Promise<void> {
  await ref.delete();
}

export async function deleteRoomRegularlyReservation(
  ref: firebase.firestore.DocumentReference
): Promise<void> {
  await ref.delete();
}
