import { convertToRole, Role, roleCollectionKey } from "@/entities/role";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export async function signIn(email: string, password: string): Promise<Role> {
  const auth = firebase.auth();
  const db = firebase.firestore();

  const userCredential = await auth.signInWithEmailAndPassword(email, password);

  const firebaseUser = userCredential.user;

  if (!firebaseUser) {
    throw new Error("Failed to get user");
  }

  const snapshot = await db
    .collection(roleCollectionKey)
    .doc(firebaseUser.uid)
    .get();
  const data = snapshot.data();
  if (!snapshot.exists || !data) {
    throw new Error("Failed to get role data");
  }
  return convertToRole(data, snapshot.ref);
}

export async function signOut(): Promise<void> {
  await firebase.auth().signOut();
}

export async function getRole(id: string): Promise<Role | null> {
  const snapshot = await firebase
    .firestore()
    .collection(roleCollectionKey)
    .doc(id)
    .get();
  const data = snapshot.data();
  if (!snapshot.exists || !data) {
    return null;
  }
  return convertToRole(data, snapshot.ref);
}

export function getEmail(): string {
  const user = firebase.auth().currentUser;
  if (!user) {
    return "";
  }

  return user.email ?? "";
}

export function isVerifiedEmail(): boolean {
  const user = firebase.auth().currentUser;
  if (!user) {
    return false;
  }
  return user.email !== null && user.emailVerified;
}

export async function updateEmail(email: string): Promise<void> {
  const user = firebase.auth().currentUser;
  if (!user) {
    throw new Error("ユーザー情報の取得に失敗しました");
  }

  await user.updateEmail(email);
}

export async function sendEmailVerification(): Promise<void> {
  const user = firebase.auth().currentUser;
  if (!user) {
    throw new Error("ユーザー情報の取得に失敗しました");
  }
  await user.sendEmailVerification();
}

export async function sendResetPasswordEmail(email: string): Promise<void> {
  await firebase.auth().sendPasswordResetEmail(email);
}
