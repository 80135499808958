import { MessageType, MessageUser, MessageContent } from "@/entities/message";
import {
  convertToMessageRegularlyReservation,
  MessageRegularlyReservation,
  messageRegularlyReservationCollectionKey,
  MessageRegularlyReservationData
} from "@/entities/message_regularly_reservation";
import {
  convertToMessageReservation,
  MessageReservation,
  messageReservationCollectionKey,
  MessageReservationData,
  MessageTo
} from "@/entities/message_reservation";
import { schoolCollectionKey } from "@/entities/school";
import firebase from "firebase/app";
import "firebase/firestore";

export async function createMessageReservation(
  from: MessageUser,
  to: MessageTo,
  messageType: MessageType,
  messageText: string,
  reservationTime: number,
  schoolId?: string,
  schoolName?: string,
  toOnlyRoomUsers?: boolean
): Promise<void> {
  const data: MessageReservationData = {
    from,
    to,
    messageType,
    messageText,
    reservationTime,
    toOnlyRoomUsers
  };

  if (from.type === "admin") {
    from.name += "@管理人";
    if (messageType === "text") {
      data.messageText = "みんがく管理人:\n" + messageText;
    }
  } else {
    if (messageType === "text" && schoolName) {
      data.messageText = schoolName + " " + from.name + "先生:\n" + messageText;
    }
  }
  if (schoolId) {
    await firebase
      .firestore()
      .collection(schoolCollectionKey)
      .doc(schoolId)
      .collection(messageReservationCollectionKey)
      .add(data);
  } else {
    await firebase
      .firestore()
      .collection(messageReservationCollectionKey)
      .add(data);
  }
}

export async function createMultipleMessagesReservation(
  from: MessageUser,
  to: MessageTo,
  messageType: MessageType,
  messageContents: MessageContent[],
  reservationTime: number,
  schoolId?: string,
  schoolName?: string,
  toOnlyRoomUsers?: boolean
): Promise<void> {
  const data: MessageReservationData = {
    from,
    to,
    messageType,
    messageContents,
    reservationTime,
    toOnlyRoomUsers
  };

  let fromName: string;
  if (from.type === "admin") {
    fromName = "みんがく管理人:\n";
  } else {
    fromName = schoolName + " " + from.name + "先生:\n";
  }
  messageContents.map(m => {
    if (m.type === "text") {
      m.text = fromName + m.text;
    }
    return m;
  });
  if (schoolId) {
    await firebase
      .firestore()
      .collection(schoolCollectionKey)
      .doc(schoolId)
      .collection(messageReservationCollectionKey)
      .add(data);
  } else {
    await firebase
      .firestore()
      .collection(messageReservationCollectionKey)
      .add(data);
  }
}

export async function createMessageRegularlyReservation(
  from: MessageUser,
  to: MessageTo,
  messageType: MessageType,
  messageText: string,
  nextTime: number,
  reservationType: "day" | "week" | "month",
  time: string,
  weekday?: number,
  monthday?: number,
  deletionTime?: number,
  schoolId?: string,
  schoolName?: string,
  toOnlyRoomUsers?: boolean
): Promise<void> {
  const data: MessageRegularlyReservationData = {
    from,
    to,
    messageType,
    messageText,
    nextTime,
    reservationType,
    time,
    toOnlyRoomUsers
  };

  if (reservationType === "month" && monthday !== undefined) {
    data.monthday = monthday;
  } else if (reservationType === "week" && weekday !== undefined) {
    data.weekday = weekday;
  }

  if (deletionTime) {
    data.deletionTime = deletionTime;
  }

  if (from.type === "admin") {
    from.name += "@管理人";
    if (messageType === "text") {
      data.messageText = "みんがく管理人:\n" + messageText;
    }
  } else {
    if (messageType === "text" && schoolName) {
      data.messageText = schoolName + " " + from.name + "先生:\n" + messageText;
    }
  }

  if (schoolId) {
    await firebase
      .firestore()
      .collection(schoolCollectionKey)
      .doc(schoolId)
      .collection(messageRegularlyReservationCollectionKey)
      .add(data);
  } else {
    await firebase
      .firestore()
      .collection(messageRegularlyReservationCollectionKey)
      .add(data);
  }
}

export async function createMultipleMessagesRegularlyReservation(
  from: MessageUser,
  to: MessageTo,
  messageType: MessageType,
  messageContents: MessageContent[],
  nextTime: number,
  reservationType: "day" | "week" | "month",
  time: string,
  weekday?: number,
  monthday?: number,
  deletionTime?: number,
  schoolId?: string,
  schoolName?: string,
  toOnlyRoomUsers?: boolean
): Promise<void> {
  const data: MessageRegularlyReservationData = {
    from,
    to,
    messageType,
    messageContents,
    nextTime,
    reservationType,
    time,
    toOnlyRoomUsers
  };

  if (reservationType === "month" && monthday !== undefined) {
    data.monthday = monthday;
  } else if (reservationType === "week" && weekday !== undefined) {
    data.weekday = weekday;
  }

  if (deletionTime) {
    data.deletionTime = deletionTime;
  }

  let fromName: string;
  if (from.type === "admin") {
    fromName = "みんがく管理人:\n";
  } else {
    fromName = schoolName + " " + from.name + "先生:\n";
  }
  messageContents.map(m => {
    if (m.type === "text") {
      m.text = fromName + m.text;
    }
    return m;
  });
  if (schoolId) {
    await firebase
      .firestore()
      .collection(schoolCollectionKey)
      .doc(schoolId)
      .collection(messageRegularlyReservationCollectionKey)
      .add(data);
  } else {
    await firebase
      .firestore()
      .collection(messageRegularlyReservationCollectionKey)
      .add(data);
  }
}

export async function fetchMessageReservations(
  schoolId?: string
): Promise<MessageReservation[]> {
  let snapshot: firebase.firestore.QuerySnapshot;
  if (schoolId) {
    snapshot = await firebase
      .firestore()
      .collection(schoolCollectionKey)
      .doc(schoolId)
      .collection(messageReservationCollectionKey)
      .get();
  } else {
    snapshot = await firebase
      .firestore()
      .collection(messageReservationCollectionKey)
      .get();
  }

  if (snapshot.empty) {
    return [];
  }

  return snapshot.docs.map(doc => {
    return convertToMessageReservation(doc.data(), doc.ref);
  });
}

export async function fetchMessageRegularlyReservations(
  schoolId?: string
): Promise<MessageRegularlyReservation[]> {
  let snapshot: firebase.firestore.QuerySnapshot;
  if (schoolId) {
    snapshot = await firebase
      .firestore()
      .collection(schoolCollectionKey)
      .doc(schoolId)
      .collection(messageRegularlyReservationCollectionKey)
      .get();
  } else {
    snapshot = await firebase
      .firestore()
      .collection(messageRegularlyReservationCollectionKey)
      .get();
  }

  if (snapshot.empty) {
    return [];
  }

  return snapshot.docs.map(doc =>
    convertToMessageRegularlyReservation(doc.data(), doc.ref)
  );
}

export async function updateMessageReservation(
  ref: firebase.firestore.DocumentReference,
  from: MessageUser,
  to: MessageTo,
  messageType: MessageType,
  messageText: string,
  reservationTime: number,
  schoolName?: string,
  toOnlyRoomUsers?: boolean
): Promise<void> {
  const data: MessageReservationData = {
    from,
    to,
    messageType,
    messageText,
    reservationTime,
    toOnlyRoomUsers
  };

  if (from.type === "admin") {
    from.name += "@管理人";
    if (messageType === "text") {
      data.messageText = "みんがく管理人:\n" + messageText;
    }
  } else {
    if (messageType === "text" && schoolName) {
      data.messageText = schoolName + " " + from.name + "先生:\n" + messageText;
    }
  }

  await ref.update(data);
}

export async function updateMultipleMessageReservation(
  ref: firebase.firestore.DocumentReference,
  from: MessageUser,
  to: MessageTo,
  messageType: MessageType,
  messageContents: MessageContent[],
  reservationTime: number,
  schoolName?: string,
  toOnlyRoomUsers?: boolean
): Promise<void> {
  const data: MessageReservationData = {
    from,
    to,
    messageType,
    messageContents,
    reservationTime,
    toOnlyRoomUsers
  };

  let fromName: string;
  if (from.type === "admin") {
    fromName = "みんがく管理人:\n";
  } else {
    fromName = schoolName + " " + from.name + "先生:\n";
  }
  data.messageContents!.map(m => {
    if (m.type === "text") {
      m.text = fromName + m.text;
    }
    return m;
  });
  await ref.update(data);
}

export async function updateMessageRegularlyReservation(
  ref: firebase.firestore.DocumentReference,
  from: MessageUser,
  to: MessageTo,
  messageType: MessageType,
  messageText: string,
  nextTime: number,
  reservationType: "day" | "week" | "month",
  time: string,
  weekday?: number,
  monthday?: number,
  deletionTime?: number,
  schoolName?: string,
  toOnlyRoomUsers?: boolean
): Promise<void> {
  const data: MessageRegularlyReservationData = {
    from,
    to,
    messageType,
    messageText,
    nextTime,
    reservationType,
    time,
    toOnlyRoomUsers
  };

  if (reservationType === "month" && monthday !== undefined) {
    data.monthday = monthday;
  } else if (reservationType === "week" && weekday !== undefined) {
    data.weekday = weekday;
  }

  if (deletionTime) {
    data.deletionTime = deletionTime;
  }

  if (from.type === "admin") {
    from.name += "@管理人";
    if (messageType === "text") {
      data.messageText = "みんがく管理人:\n" + messageText;
    }
  } else {
    if (messageType === "text" && schoolName) {
      data.messageText = schoolName + " " + from.name + "先生:\n" + messageText;
    }
  }

  await ref.update(data);
}

export async function updateMultipleMessageRegularlyReservation(
  ref: firebase.firestore.DocumentReference,
  from: MessageUser,
  to: MessageTo,
  messageType: MessageType,
  messageContents: MessageContent[],
  nextTime: number,
  reservationType: "day" | "week" | "month",
  time: string,
  weekday?: number,
  monthday?: number,
  deletionTime?: number,
  schoolName?: string,
  toOnlyRoomUsers?: boolean
): Promise<void> {
  const data: MessageRegularlyReservationData = {
    from,
    to,
    messageType,
    messageContents,
    nextTime,
    reservationType,
    time,
    toOnlyRoomUsers
  };

  if (reservationType === "month" && monthday !== undefined) {
    data.monthday = monthday;
  } else if (reservationType === "week" && weekday !== undefined) {
    data.weekday = weekday;
  }

  if (deletionTime) {
    data.deletionTime = deletionTime;
  }

  let fromName: string;
  if (from.type === "admin") {
    fromName = "みんがく管理人:\n";
  } else {
    fromName = schoolName + " " + from.name + "先生:\n";
  }
  data.messageContents!.map(m => {
    if (m.type === "text") {
      m.text = fromName + m.text;
    }
    return m;
  });

  await ref.update(data);
}

export async function deleteMessageReservation(
  ref: firebase.firestore.DocumentReference
): Promise<void> {
  await ref.delete();
}

export async function deleteMessageRegularlyReservation(
  ref: firebase.firestore.DocumentReference
): Promise<void> {
  await ref.delete();
}
