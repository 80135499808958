import {
  Classroom,
  classroomCollectionKey,
  convertToClassroom
} from "@/entities/classroom";
import {
  schoolCollectionKey,
  schoolConfigCollectionKey
} from "@/entities/school";
import firebase from "firebase/app";
import "firebase/firestore";
import { getStudentsOfClassroom } from "./student";

export async function getClassroom(
  ref: firebase.firestore.DocumentReference
): Promise<Classroom | null> {
  const snapshot = await ref.get();
  const data = snapshot.data();
  if (!snapshot.exists || !data) {
    return null;
  }

  return convertToClassroom(data, snapshot.ref);
}

// tutor が取得可能な classroom を一括取得
export async function fetchClassroomsOfTutor(
  tutorId: string,
  schoolRef: firebase.firestore.DocumentReference
): Promise<Classroom[]> {
  const snapshot = await schoolRef
    .collection(classroomCollectionKey)
    .where("tutors", "array-contains", tutorId)
    .get();
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc => convertToClassroom(doc.data(), doc.ref));
}

export async function fetchClassroomsOfSchool(
  schoolRef: firebase.firestore.DocumentReference
): Promise<Classroom[]> {
  const snapshot = await schoolRef.collection(classroomCollectionKey).get();
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc => convertToClassroom(doc.data(), doc.ref));
}

export async function getChildrenSchoolIdsFromParent(id: string) {
  const db = firebase.firestore();
  const arrayName = "childrenSchoolDocIds";
  const snapshot = await db
    .collection(schoolConfigCollectionKey)
    .doc(id)
    .get();
  if (!snapshot) {
    return [];
  }
  const data = snapshot.data();

  if (data && Array.isArray(data[arrayName]) && data[arrayName].length > 0) {
    return data![arrayName];
  } else {
    return [];
  }
}

export async function getChildrenScoolClassRooms(
  parentScoolId: string
): Promise<Classroom[]> {
  const db = firebase.firestore();
  const childrenSchoolIds = await getChildrenSchoolIdsFromParent(parentScoolId);

  const classroomPromises = childrenSchoolIds.map((schoolId: string) => {
    const childScholRef = db.collection(schoolCollectionKey).doc(schoolId);
    return childScholRef
      .collection(classroomCollectionKey)
      .get()
      .then(classroomsSnapshot => {
        return classroomsSnapshot.docs.map(classroomDoc =>
          convertToClassroom(classroomDoc.data(), classroomDoc.ref)
        );
      });
  });

  const classroomsArray: Classroom[][] = await Promise.all(classroomPromises);
  return classroomsArray.flat();
}

export async function getchildrenSchoolStudents(parentScoolId: string) {
  const classrooms: Classroom[] = await getChildrenScoolClassRooms(
    parentScoolId
  );

  const studentsOfClassrooms = await Promise.all(
    classrooms.map(item => getStudentsOfClassroom(item.ref))
  );

  const students = studentsOfClassrooms
    .filter(item => item !== null)
    .reduce((pre, current) => {
      pre.push(...current);
      return pre;
    }, []);

  return students;
}

export async function deleteClassroom(
  ref: firebase.firestore.DocumentReference
): Promise<void> {
  await ref.delete();
}

export async function registerClassroom(
  id: string,
  name: string,
  tutorIds: string[]
): Promise<void> {
  const registerClassroom = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("register_classroom");

  const res = await registerClassroom({ id, name, tutors: tutorIds });
  if (!res.data.success) {
    throw new Error("予期せぬエラーが発生しました");
  }
}

export async function updateClassroom(
  schoolId: string,
  classroomId: string,
  data: {
    id?: string;
    name?: string;
    postalCode?: string;
    address?: string;
    tutors?: string[];
  }
): Promise<void> {
  const updateClassroom = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("update_classroom");

  const res = await updateClassroom({
    school: schoolId,
    classroom: classroomId,
    data
  });
  if (!res.data.success) {
    throw new Error("予期せぬエラーが発生しました");
  }
}

export function getClassRoomsBasedOnRole(
  isServiceProvider: boolean,
  classRoomsInStore: Classroom[],
  mySchoolDocId: string
): Classroom[] {
  if (!classRoomsInStore) {
    throw new Error("Store内に教室データが見つかりません");
  }
  if (!mySchoolDocId) {
    throw new Error("現在ログインしているスクールのIDが見つかりません");
  }

  if (!isServiceProvider) return classRoomsInStore;

  return classRoomsInStore.filter(classRoom => {
    const classRoomRef = classRoom.ref;
    const path = classRoomRef.path;
    const pathSegments = path.split("/");
    const schoolId =
      pathSegments[pathSegments.indexOf(schoolCollectionKey) + 1];
    return schoolId === mySchoolDocId;
  });
}
