import {
  Badge,
  badgeCollectionKey,
  convertToBadge,
  convertToHasBadge,
  HasBadge,
  hasBadgeCollectionKey
} from "@/entities/badge";
import firebase from "firebase/app";
import "firebase/firestore";

export async function fetchBadges(): Promise<Badge[]> {
  const db = firebase.firestore();
  const snapshot = await db
    .collection(badgeCollectionKey)
    .orderBy("order", "asc")
    .get();
  if (snapshot.empty) {
    return [];
  }

  return snapshot.docs.map(doc => convertToBadge(doc.data(), doc.ref));
}

export async function fetchHasBadges(
  studentRef: firebase.firestore.DocumentReference
): Promise<HasBadge[]> {
  const snapshot = await studentRef.collection(hasBadgeCollectionKey).get();
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc => convertToHasBadge(doc.data(), doc.ref));
}
