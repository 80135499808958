import firebase from "firebase/app";

export const roleCollectionKey = "roles";

export type RoleType = "student" | "tutor" | "admin";

export type Role = {
  ref: firebase.firestore.DocumentReference;
  data: RoleData;
};

export type RoleData = {
  ref: firebase.firestore.DocumentReference;
  type: RoleType;
  loginId?: string;
  code?: string;
};

export function convertToRole(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Role {
  const returnData: RoleData = {
    ref: data.ref,
    type: data.type
  };
  if (data.loginId) {
    returnData.loginId = data.loginId;
  }
  if (data.code) {
    returnData.code = data.code;
  }

  return {
    ref,
    data: returnData
  };
}
