import { registrantCollectionKey } from "@/entities/registrant";
import { getDocIdsOfStudent, Student } from "@/entities/student";
import firebase from "firebase/app";
import "firebase/firestore";

export function getRegistrantStreamOfRoom(
  roomRef: firebase.firestore.DocumentReference
): firebase.firestore.Query {
  return roomRef.collection(registrantCollectionKey);
}

export async function deleteRegistrantOfStudent(student: Student, end: number) {
  const [school, classroom, uid] = getDocIdsOfStudent(student);
  const deleteRoom = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("delete_registrant");

  const res = await deleteRoom({ school, classroom, uid, end });
  if (!res.data.success) {
    throw new Error("unexpected error occured");
  }
}
