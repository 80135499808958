import { RelatedLink, isExternalFileUrl } from "@/entities/submission_master";
import {
  threadMasterCollectionKey,
  ThreadMasterData,
  ThreadMaster,
  convertToThreadMaster
} from "@/entities/thread_master";
import { schoolCollectionKey } from "@/entities/school";
import { checkIsFileShouldProtected } from "@/api/submission_master";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

export async function createThreadMaster(
  schoolDocId: string,
  name: string,
  label: string,
  studentRefs: firebase.firestore.DocumentReference[],
  submittableFrom: number,
  displayEndAt: number,
  classroomIds: string[],
  description: string,
  relatedLinks: RelatedLink[]
): Promise<void> {
  const data: ThreadMasterData = {
    name,
    label,
    description,
    relatedLinks,
    studentRefs,
    submittableFrom,
    displayEndAt,
    classroomIds
  };

  await firebase
    .firestore()
    .collection(schoolCollectionKey)
    .doc(schoolDocId)
    .collection(threadMasterCollectionKey)
    .add(data);
}

export async function getThreadMasterByClassroomIds(
  schoolId: string,
  classroomsIds: string[],
  term?: { start: number; end: number }
): Promise<ThreadMaster[]> {
  const snapshot = await firebase
    .firestore()
    .collection(schoolCollectionKey)
    .doc(schoolId)
    .collection(threadMasterCollectionKey)
    .get();
  if (snapshot.empty) {
    return [];
  }

  const filteredData = snapshot.docs.filter(doc => {
    const data = doc.data();

    const classroomIdsOfThreadMasters = data.classroomIds as string[]; // ThreadMasterを管轄するclassroomIdを取得
    const classroomIdsIncludedInBoth = classroomIdsOfThreadMasters.filter(
      (id: string) => classroomsIds.includes(id)
    ); // 先生が管轄するclassroomとthreadを管轄するclassroomの共通項を取得
    const isHomeroom = classroomIdsIncludedInBoth.length !== 0; // 共通するclassroomがなければ、管轄外なので、falseを返却

    const isFilteredTerm =
      !term || term.start === 0 || term.end === 0
        ? true
        : data.displayEndAt > term.start && data.displayEndAt < term.end;
    return isHomeroom && isFilteredTerm;
  });

  return filteredData.map(doc => convertToThreadMaster(doc.data(), doc.ref));
}

export async function updateThreadMaster(
  ref: firebase.firestore.DocumentReference,
  data: ThreadMasterData
): Promise<void> {
  await ref.update(data);
}

export async function deleteThreadMaster(
  ref: firebase.firestore.DocumentReference
): Promise<void> {
  // 生徒用提出物削除処理
  const threadSnapshot = await firebase
    .firestore()
    .collectionGroup("threads")
    .where("threadMasterRef", "==", ref)
    .get();
  threadSnapshot.docs.map(async doc => {
    doc.data().posts.map(async ({ url }: { url: string }) => {
      try {
        await firebase.storage().refFromURL(url).delete();
      } catch (e) {
        // Exception Ignored
      }
    });
    await doc.ref.delete();
  });
  // 提出物マスター削除処理
  const snapshot = await ref.get();
  const data = snapshot.data();
  if (!snapshot.exists || !data) {
    throw new Error("no threadMaster found"); // 通常ありえない
  }
  await Promise.all(
    data.relatedLinks
      .filter(({ url }: { url: string }) => !isExternalFileUrl(url))
      .map(async ({ url }: { url: string }) => {
        if (!checkIsFileShouldProtected(url, ref)) {
          try {
            await firebase.storage().refFromURL(url).delete();
          } catch (e) {
            // Exception Ignored
          }
        }
      })
  );
  await ref.delete();
}
