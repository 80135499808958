import {
  convertToTutor,
  convertToTutorConfig,
  Tutor,
  TutorAuthority,
  tutorCollectionKey,
  TutorConfig,
  tutorConfigCollectionKey
} from "@/entities/tutor";
import firebase from "firebase/app";
import "firebase/firestore";
import { getCurrentUnixtime } from "@/utils/date";

export type TutorMeta = {
  main: Tutor;
  config: TutorConfig;
};
export async function getTutor(
  ref: firebase.firestore.DocumentReference
): Promise<Tutor | null> {
  const snapshot = await ref.get();
  const data = snapshot.data();
  if (!snapshot.exists || !data) {
    return null;
  }
  if (!data.selectedPrompt) {
    const db = firebase.firestore();
    const promptDoc = await db
      .collection("prompt")
      .doc("promptForTutors")
      .get();
    data.selectedPrompt = [
      {
        name: promptDoc.data()!.name,
        text: promptDoc.data()!.text,
        docId: promptDoc.id
      }
    ];
  }
  return convertToTutor(data, snapshot.ref);
}

export async function fetchTutorConfig(
  ref: firebase.firestore.DocumentReference
): Promise<TutorConfig | null> {
  const snapshot = await ref.get();
  const data = snapshot.data();
  if (!snapshot.exists || !data) {
    return null;
  }

  return convertToTutorConfig(data, snapshot.ref);
}

export async function fetchTutorPrompts(
  ref: firebase.firestore.DocumentReference
): Promise<
  | {
      ref: firebase.firestore.DocumentReference<
        firebase.firestore.DocumentData
      >;
      data: { name: string; text: string };
    }[]
  | null
> {
  const snapshot = await ref.collection("prompts").get();
  if (snapshot.docs.length === 0) {
    const tutorSnapshot = await ref.get();
    const data = tutorSnapshot.data();
    if (!tutorSnapshot.exists || !data) {
      return null;
    }
    let prompt = "";
    if (!data.prompt) {
      const db = firebase.firestore();
      const promptDoc = await db
        .collection("prompt")
        .doc("promptForTutors")
        .get();
      prompt = promptDoc.data()!.text;
    } else {
      prompt = data.prompt;
    }
    const newDoc = ref.collection("prompts").doc().id;
    await ref
      .collection("prompts")
      .doc(newDoc)
      .set({
        name: "デフォルトモード",
        text: prompt,
        updatedAt: getCurrentUnixtime()
      });
    await ref.update({
      selectedPrompt: { name: "デフォルトモード", text: prompt, docId: newDoc }
    });
  }
  const snapshot2 = await ref.collection("prompts").get();
  return snapshot2.docs.map(doc => {
    const ref = doc.ref;
    const data = doc.data() as { name: string; text: string };
    return { ref, data };
  });
}

export async function fetchTutorsOfSchool(
  schoolRef: firebase.firestore.DocumentReference
): Promise<Tutor[]> {
  const snapshot = await schoolRef.collection(tutorCollectionKey).get();
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc => convertToTutor(doc.data(), doc.ref));
}

export async function fetchTutorConfigsOfSchool(
  schoolRef: firebase.firestore.DocumentReference
): Promise<TutorConfig[]> {
  const snapshot = await schoolRef.collection(tutorConfigCollectionKey).get();
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc => convertToTutorConfig(doc.data(), doc.ref));
}

export async function updateTutorName(
  ref: firebase.firestore.DocumentReference,
  name: string
): Promise<void> {
  await ref.update({ name });
}

export async function updateTutorPrompt(
  ref: firebase.firestore.DocumentReference,
  prompt: { name: string; text: string }
): Promise<void> {
  await ref.collection("prompts").add(prompt);
}

export async function updateTutorAllowEmailNotify(
  ref: firebase.firestore.DocumentReference,
  allowEmailNotify: boolean,
  allowBadgeEmailNotify: boolean
) {
  await ref.update({ allowEmailNotify, allowBadgeEmailNotify });
}

export async function deleteTutor(ref: firebase.firestore.DocumentReference) {
  await ref.delete();
}

export async function registerTutor(
  name: string,
  email: string,
  password: string,
  authority: TutorAuthority,
  classroomIds: string[]
) {
  const registerTutor = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("register_tutor");

  const res = await registerTutor({
    name,
    email,
    password,
    authority,
    classrooms: classroomIds
  });
  if (!res.data.success) {
    throw new Error("予期せぬエラーが発生しました");
  }
}

export async function updateTutor(
  tutorId: string,
  data: {
    name?: string;
    authority?: TutorAuthority;
    classrooms?: string[];
  }
) {
  const updateTutor = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("update_tutor");

  const res = await updateTutor({
    tutor: tutorId,
    data
  });
  if (!res.data.success) {
    throw new Error("予期せぬエラーが発生しました");
  }
}

export async function tutorUpdatePassword(
  ref: firebase.firestore.DocumentReference,
  password: string
) {
  const call = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("tutor_update_initial_password");
  const res = await call({ tutorId: ref.id, password });
  if (!res.data.success) throw new Error("予期せぬエラーが発生しました");
}
