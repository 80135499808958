import firebase from "firebase/app";

export const threadMasterCollectionKey = "threadMasters";

/*
    Tutorがあるグループに対して設定する
    提出物マスターデータのドキュメント。
*/
export type ThreadMaster = {
  ref: firebase.firestore.DocumentReference;
  data: ThreadMasterData;
};

export type ThreadMasterRelatedLink = {
  label: string;
  url: string;
};

export type ThreadMasterData = {
  name: string;
  label: string;
  description: string; // 備考
  relatedLinks: ThreadMasterRelatedLink[];
  studentRefs: firebase.firestore.DocumentReference[];
  submittableFrom: number; // 生徒側に表示される unixTime
  displayEndAt?: number; // 表示終了日(生徒画面で表示されなくなる日) unixtime
  classroomIds: string[]; // classroomIdの配列
};

export const convertToThreadMasterData = (
  data: firebase.firestore.DocumentData
) => {
  const result = {
    name: data.name,
    label: data.label,
    description: data.description,
    relatedLinks: data.relatedLinks,
    studentRefs: data.studentRefs,
    submittableFrom: data.submittableFrom,
    displayEndAt: data.displayEndAt ?? 0,
    classroomIds: data.classroomIds
  } as ThreadMasterData;
  return result;
};

export const convertToThreadMaster = (
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): ThreadMaster => {
  return {
    ref,
    data: convertToThreadMasterData(data)
  } as ThreadMaster;
};
