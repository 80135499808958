import firebase from "firebase/app";

export const roomReservationCollectionKey = "roomReservations";
export const roomReservationOfPopyCollectionKey = "roomReservationsOfPopy";

export type RoomReservation = {
  ref: firebase.firestore.DocumentReference;
  data: RoomReservationData;
};

export type RoomReservationData = {
  name: string; // 開室予定の学習室名
  labels: firebase.firestore.DocumentReference[];
  end?: number | null;
  target?: firebase.firestore.DocumentReference; // 学習室の対象領域
  group?: firebase.firestore.DocumentReference; // 学習室の対象グループ
  groupName: string;
  withNotification: boolean; // 開室通知の有無
  reservationTime: number; // 予約時間
  mtelHost?: string;
};

export type RoomReservationOfPopy = {
  ref: firebase.firestore.DocumentReference;
  data: RoomReservationOfPopyData;
};

export type RoomReservationOfPopyData = {
  name: string; // 開室予定の学習室名
  labels: firebase.firestore.DocumentReference[];
  end?: number | null;
  target?: firebase.firestore.DocumentReference; // 学習室の対象領域
  group?: firebase.firestore.DocumentReference; // 学習室の対象グループ
  groupName: string;
  withNotification: boolean; // 開室通知の有無
  reservationTime: number; // 予約時間
  mtelHost?: string;
  openingVideo: string,
  closingVideo: string,
};

export function convertToRoomReservation(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): RoomReservation {
  const returnData: RoomReservationData = {
    name: data.name,
    labels: data.labels,
    end: data.end ?? null,
    groupName: data.groupName,
    withNotification: data.withNotification,
    reservationTime: data.reservationTime
  };

  if (data.target) {
    returnData.target = data.target;
  }

  if (data.group) {
    returnData.group = data.group;
  }
  
  if (data.mtelHost) {
    returnData.mtelHost = data.mtelHost;
  }

  return {
    ref,
    data: returnData
  };
}
