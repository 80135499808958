// MessagePanel 用のvuex

import { saveErrorLog } from "@/api/error";
import { fetchMessages, getMessageStream } from "@/api/message";
import { getMessageEditorStream } from "@/api/message_editor";
import {
  convertToMessage,
  Message,
  messageTextsToAvoidNotification,
  MessageContent
} from "@/entities/message";
import {
  convertToMessageEditor,
  MessageEditor
} from "@/entities/message_editor";
import firebase from "firebase/app";
import { Module } from "vuex";
import { RootState } from "..";
import { getSchoolConfig } from "@/api/school";
import { SchoolConfig, schoolConfigCollectionKey } from "@/entities/school";

interface MessagePanelState {
  messageUserRef: firebase.firestore.DocumentReference | null;
  messageStream: (() => void) | null;
  messages: Message[];
  messageEditorStream: (() => void) | null;
  messageEditors: MessageEditor[];
  messageLoading: boolean;
  messageLimit: number;
  messageFinished: boolean;
  messageUserSchoolConfig: SchoolConfig | null;
}

const isMessageToIgnore = (messageText: string) => {
  return messageTextsToAvoidNotification.some(
    pattern => messageText === pattern
  );
};

const messageLimit = 20;

const messagePanelModule: Module<MessagePanelState, RootState> = {
  namespaced: true,

  state: {
    messageUserRef: null,
    messageStream: null,
    messages: [],
    messageEditorStream: null,
    messageEditors: [],
    messageLoading: false,
    messageLimit: messageLimit,
    messageFinished: false,
    messageUserSchoolConfig: null
  },

  getters: {},

  mutations: {
    SET_MESSAGE_USER_REF(state, ref: firebase.firestore.DocumentReference) {
      state.messageUserRef = ref;
    },
    SET_MESSAGE_STREAM(state, stream: (() => void) | null) {
      state.messageStream = stream;
    },
    SET_MESSAGES(state, messages: Message[]) {
      state.messages = messages;
    },
    SET_MESSAGE_USER_SCHOOL_CONFIG(state, schoolConfig: SchoolConfig) {
      state.messageUserSchoolConfig = schoolConfig;
    },
    SET_MESSAGE_EDITOR_STREAM(state, stream: (() => void) | null) {
      state.messageEditorStream = stream;
    },
    SET_MESSAGE_EDITORS(state, editors: MessageEditor[]) {
      state.messageEditors = editors;
    },
    SET_MESSAGE_LOADING(state, loading: boolean) {
      state.messageLoading = loading;
    },
    SET_MESSAGE_FINISHED(state, finished: boolean) {
      state.messageFinished = finished;
    },
    CLEAR(state) {
      state.messageUserRef = null;
      state.messageStream = null;
      state.messages = [];
      state.messageEditorStream = null;
      state.messageEditors = [];
      state.messageLoading = false;
      state.messageFinished = false;
    }
  },

  actions: {
    async changeMessageStream(
      context,
      studentRef: firebase.firestore.DocumentReference
    ) {
      if (
        context.state.messageUserRef &&
        context.state.messageUserRef.id === studentRef.id
      ) {
        return;
      }

      context.commit("SET_MESSAGE_FINISHED", false);

      if (context.state.messageStream !== null) {
        context.state.messageStream();
      }

      context.commit("SET_MESSAGES", []);

      const stream = getMessageStream(studentRef, 1).onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          let currentMessages = context.state.messages;
          if (change.type === "added") {
            if (change.doc.data().type !== "multiple") {
              const changedMessage = convertToMessage(
                change.doc.data(),
                change.doc.ref
              );
              if (!isMessageToIgnore(changedMessage.data.messageText!)) {
                currentMessages.push(changedMessage);
                currentMessages.sort(
                  (a, b) => b.data.timestamp - a.data.timestamp
                );
              }
            } else {
              change.doc.data().messageContents.forEach((c: MessageContent) => {
                currentMessages.push(
                  convertToMessage(
                    { ...change.doc.data(), messageText: c.text, type: c.type },
                    change.doc.ref
                  )
                );
              });
              currentMessages.sort(
                (a, b) => b.data.timestamp - a.data.timestamp
              );
            }
          } else if (change.type === "modified") {
            // 単一での削除なのでmultiでは渡ってこない前提
            const changedMessage = convertToMessage(
              change.doc.data(),
              change.doc.ref
            );
            if (changedMessage.data.deleted) {
              currentMessages = currentMessages.filter(
                m => m.ref.id !== changedMessage.ref.id
              );
              currentMessages.sort(
                (a, b) => b.data.timestamp - a.data.timestamp
              );
            }
          }
          context.commit(
            "SET_MESSAGES",
            currentMessages.filter(_ => !_.data.deleted)
          );
        });
      });
      context.commit("SET_MESSAGE_USER_REF", studentRef);
      context.commit("SET_MESSAGE_STREAM", stream);
      context.dispatch("changeMessageEditorStream", studentRef);

      try {
        const schoolDoId = studentRef.parent.parent!.parent.parent!.id;
        const db = firebase.firestore();
        const schoolConfigRef = db
          .collection(schoolConfigCollectionKey)
          .doc(schoolDoId);
        const [messages, schoolConfig] = await Promise.all([
          fetchMessages(studentRef, messageLimit),
          getSchoolConfig(schoolConfigRef)
        ]);
        context.commit("SET_MESSAGE_USER_SCHOOL_CONFIG", schoolConfig);
        const newMessages = context.state.messages;
        messages.forEach(message => {
          const messageIds = newMessages.map(m => m.ref.id);
          if (
            messageIds[0] !== message.ref.id &&
            !isMessageToIgnore(message.data.messageText!)
          ) {
            newMessages.push(message);
          }
        });
        newMessages.sort((a, b) => b.data.timestamp - a.data.timestamp);
        context.commit(
          "SET_MESSAGES",
          newMessages.filter(_ => !_.data.deleted)
        );
        if (messages.length < messageLimit) {
          context.commit("SET_MESSAGE_FINISHED", true);
        }
      } catch (e) {
        alert(`メッセージデータの取得に失敗しました\n\n${e}`);
        await saveErrorLog(
          context.rootState.role,
          e.code,
          e.message,
          "Failed to get messages"
        );
        return;
      }
    },
    async getMoreMessage(context) {
      if (
        context.state.messageFinished ||
        context.state.messageLoading ||
        !context.state.messageUserRef ||
        context.state.messages.length === 0
      ) {
        return;
      }

      const lastMessage =
        context.state.messages[context.state.messages.length - 1];
      context.commit("SET_MESSAGE_LOADING", true);
      try {
        const messages = await fetchMessages(
          context.state.messageUserRef,
          messageLimit,
          lastMessage.ref
        );
        const newMessages = context.state.messages;
        messages.forEach(message => {
          if (!isMessageToIgnore(message.data.messageText!)) {
            newMessages.push(message);
          }
        });
        newMessages.sort((a, b) => b.data.timestamp - a.data.timestamp);
        context.commit(
          "SET_MESSAGES",
          newMessages.filter(_ => !_.data.deleted)
        );
        if (messages.length < messageLimit) {
          context.commit("SET_MESSAGE_FINISHED", true);
        }
      } catch (e) {
        alert(`メッセージの取得に失敗しました\n\n${e}`);
        await saveErrorLog(
          context.rootState.role,
          e.code,
          e.message,
          "Failed to get messages"
        );
      }
      context.commit("SET_MESSAGE_LOADING", false);
    },
    async changeMessageEditorStream(
      context,
      studentRef: firebase.firestore.DocumentReference
    ) {
      if (context.state.messageEditorStream !== null) {
        context.state.messageEditorStream();
      }

      context.commit("SET_MESSAGE_EDITORS", []);
      const stream = getMessageEditorStream(
        studentRef,
        Math.floor(Date.now() / 1000 - 10)
      ).onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          let currentEditors = [...context.state.messageEditors];
          const changeEditor: MessageEditor = convertToMessageEditor(
            change.doc.data(),
            change.doc.ref
          );
          if (change.type === "added") {
            currentEditors.push(changeEditor);
          } else if (change.type === "modified") {
            currentEditors = currentEditors.filter(
              editor => editor.ref.id !== changeEditor.ref.id
            );
            currentEditors.push(changeEditor);
          }
          context.commit("SET_MESSAGE_EDITORS", currentEditors);
        });
      });
      context.commit("SET_MESSAGE_EDITOR_STREAM", stream);
    }
  }
};

export default messagePanelModule;
