import firebase from "firebase/app";

export const reservationCollectionKey = "reservations";

export type Reservation = {
  ref: firebase.firestore.DocumentReference;
  data: ReservationData;
};

export type ReservationData = {
  beforeMinute: number; // 通知時間から実際の予約時間までの間
  notifyTime: number; // 通知時間
  notified: boolean; // 通知済かどうか
};

export type ReservationEditingRow = {
  ref?: firebase.firestore.DocumentReference;
  data: {
    date: string;
    time: string;
  };
};
export type RegularlyReservationEditingRow = {
  ref?: firebase.firestore.DocumentReference;
  data: {
    type: "day" | "week" | "month";
    time: string;
    useDeletionTime: boolean;
    weekday?: string;
    monthday?: number;
    deletionTime?: string;
  };
};

// reservation の [schoolDocId, classroomDocId, studentDocId, reservaiontDocId] を返す
export function getDocIdsOfReservation(
  reservation: Reservation
): [string, string, string, string] {
  const reservationRef = reservation.ref;
  if (!reservationRef.parent.parent) {
    return ["", "", "", ""];
  }
  const studentRef = reservationRef.parent.parent;
  if (!studentRef.parent.parent) {
    return ["", "", "", ""];
  }
  const classroomRef = studentRef.parent.parent;
  if (!classroomRef.parent.parent) {
    return ["", "", "", ""];
  }
  const schoolRef = classroomRef.parent.parent;
  return [schoolRef.id, classroomRef.id, studentRef.id, reservationRef.id];
}

export function convertToReservation(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Reservation {
  const returnData: ReservationData = {
    beforeMinute: data.beforeMinute,
    notifyTime: data.notifyTime,
    notified: data.notified
  };

  return {
    ref,
    data: returnData
  };
}
