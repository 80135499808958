import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { convertToMiniApp, MiniApp } from "@/entities/mini_app";

export async function getMiniApps(): Promise<MiniApp[]> {
  const db = firebase.firestore();
  const snapshot = await db.collection("miniApps").get();
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs
    .map(doc => convertToMiniApp(doc.data(), doc.ref))
    .filter(miniApp => miniApp.ref.id !== "miniApps"); // みんがくアプリ ver1.0.1以降のための対応
}

export async function getMiniAppsOfSchool(
  ref: firebase.firestore.DocumentReference
): Promise<MiniApp[]> {
  const snapshot = await ref.collection("miniApps").get();
  // 空っぽだったらまずはアドミンのminiAppsを踏襲する
  if (snapshot.empty) {
    return await getMiniApps();
  }
  return snapshot.docs
    .map(doc => convertToMiniApp(doc.data(), doc.ref))
    .filter(miniApp => miniApp.ref.id !== "miniApps"); // みんがくアプリ ver1.0.1以降のための対応
}
