import { Admin, adminCollectionKey } from "@/entities/admin";
import firebase from "firebase/app";
import "firebase/firestore";
import { getCurrentUnixtime } from "@/utils/date";

export async function getAdmin(id: string): Promise<Admin | null> {
  const db = firebase.firestore();

  const snapshot = await db
    .collection(adminCollectionKey)
    .doc(id)
    .get();
  const data = snapshot.data();
  if (!snapshot.exists || !data) {
    return null;
  }

  if (!data.selectedPrompt) {
    const db = firebase.firestore();
    const promptDoc = await db
      .collection("prompt")
      .doc("promptForTutors")
      .get();
    data.selectedPrompt = [
      {
        name: promptDoc.data()!.name,
        text: promptDoc.data()!.text,
        docId: promptDoc.id
      }
    ];
  }
  return {
    ref: snapshot.ref,
    name: data.name,
    isSuperAdmin: data.isSuperAdmin ?? false,
    prompt: data.selectedPrompt
  };
}

export async function fetchAdminPrompts(
  id: string
): Promise<
  | {
      ref: firebase.firestore.DocumentReference<
        firebase.firestore.DocumentData
      >;
      data: { name: string; text: string };
    }[]
  | null
> {
  const db = firebase.firestore();

  const adminSnapshot = await db
    .collection(adminCollectionKey)
    .doc(id)
    .get();
  const ref = adminSnapshot.ref;
  if (!adminSnapshot.exists || !ref) {
    return null;
  }
  const snapshot = await ref.collection("prompts").get();
  if (snapshot.docs.length === 0) {
    const adminSnapshot = await ref.get();
    const data = adminSnapshot.data();
    if (!adminSnapshot.exists || !data) {
      return null;
    }
    let prompt = "";
    if (!data.prompt) {
      const db = firebase.firestore();
      const promptDoc = await db
        .collection("prompt")
        .doc("promptForTutors")
        .get();
      prompt = promptDoc.data()!.text;
    } else {
      prompt = data.prompt;
    }
    const newDoc = ref.collection("prompts").doc().id;
    await ref
      .collection("prompts")
      .doc(newDoc)
      .set({
        name: "デフォルトモード",
        text: prompt,
        updatedAt: getCurrentUnixtime()
      });
    await ref.update({
      selectedPrompt: { name: "デフォルトモード", text: prompt, docId: newDoc }
    });
  }
  const snapshot2 = await ref.collection("prompts").get();
  return snapshot2.docs.map(doc => {
    const ref = doc.ref;
    const data = doc.data() as { name: string; text: string };
    return { ref, data };
  });
}

export async function updateAdminName(
  ref: firebase.firestore.DocumentReference,
  name: string
): Promise<void> {
  await ref.update({ name });
}

export async function updateAdminPrompt(
  ref: firebase.firestore.DocumentReference,
  prompt: { name: string; text: string }[]
): Promise<void> {
  await ref.update({ prompt });
}
