import firebase from "firebase/app";

export const stickerCollectionKey = "stickers";

export type Sticker = {
  ref: firebase.firestore.DocumentReference;
  data: StickerData;
};

export type StickerData = {
  name: string; // スタンプ名
  imageUrl: string; // 画像ファイルへのリンク
  order: number; //  並び順番号
};

export function convertToSticker(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Sticker {
  return {
    ref,
    data: {
      name: data.name,
      imageUrl: data.imageUrl,
      order: data.order
    }
  };
}
