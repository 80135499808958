import firebase from "firebase/app";

export const miniAppCollectionKey = "miniApps";

export type MiniApp = {
  ref: firebase.firestore.DocumentReference;
  data: MiniAppData;
};

export type MiniAppData = {
  name: string; // アプリの名称
  url: string; //アプリのURL
  displayOrder: number; //アプリの表示順
  isWebView: boolean; //アプリ内部で開くか否か
  serviceType?: string; //アプリの種類
  iconUrl?: string; //アイコンのURL（あれば）
  isDisplayNone: boolean; //生徒画面でのアプリの表示、非表示
  distributionType: "all" | "custom"; //全員に表示するかどうかのタイプ（今後タイプが増えた時用に文字列で定義）
  targetStudentIds: string[] | undefined | null; //アプリを表示する対象の生徒ID
} | null;

export function convertToMiniApp(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): MiniApp {
  if (
    data.targetStudentIds !== undefined &&
    data.targetStudentIds !== null &&
    !Array.isArray(data.targetStudentIds)
  ) {
    throw new Error("表示対象の生徒IDが配列の形式になっていません");
  }
  const returnData: MiniAppData = {
    name: data.name,
    url: data.url,
    displayOrder: data.displayOrder,
    isWebView: data.isWebView,
    serviceType: data.serviceType ?? "",
    iconUrl: data.iconUrl ?? "",
    isDisplayNone: data.isDisplayNone ?? false,
    distributionType: data.distributionType ?? "all",
    targetStudentIds: data.targetStudentIds
  };
  return {
    ref,
    data: returnData
  };
}
