/* eslint-disable @typescript-eslint/camelcase */
import { analytics } from "@/main";
import "firebase/analytics";

export function pageViewLogEvent(
  pageLocation: string,
  pagePath: string,
  pageTitle: string
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const analyticsAny = analytics as any;
  analyticsAny.logEvent("page_view", {
    page_location: pageLocation,
    page_path: pagePath,
    page_title: pageTitle
  });
}

export function setUserIdToAnalytics(userId: string) {
  analytics.setUserId(userId);
}
