import { roomCollectionKey } from "@/entities/room";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

export async function createRoom(
  name: string,
  notify: boolean,
  labels: object[],
  end: number | null,
  groupId?: string,
  targetId?: string
): Promise<string> {
  const createZoomMeeting = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("create_zoom_meeting");
  const data: Record<string, string | boolean | number | object[]> = {
    name
  };
  if (notify) {
    data.notify = true;
  }
  data.labels = labels;
  if (groupId) {
    data.group = groupId;
  }
  if (targetId) {
    data.target = targetId;
  }
  if (end) {
    data.end = end;
  }

  const res = await createZoomMeeting(data);
  if (res.data.error) {
    throw new Error(res.data.error);
  }
  if (!res.data.startUrl) {
    throw new Error("response data is invalid");
  }
  return res.data.startUrl;
}

export async function deleteRoom(
  id: string,
  end: number,
  canDeleteRoom: boolean
) {
  const deleteRoom = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("delete_room");

  const res = await deleteRoom({ id, end, canDeleteRoom });
  if (!res.data.success) {
    throw new Error("unexpected error occured");
  }
}

export function getRoomStream(): firebase.firestore.Query {
  const db = firebase.firestore();
  return db.collection(roomCollectionKey).orderBy("start", "asc");
}

export async function updateEndTimeOfRoom(id: string, end: number | null) {
  const db = firebase.firestore();
  const ref = db.collection(roomCollectionKey).doc(id);
  await ref.update({ end: end });
}
