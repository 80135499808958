import firebase from "firebase/app";

export type MainButtonSettingType = {
  ref: firebase.firestore.DocumentReference;
  data: MainButtonSettingDataType;
};

export type MainButtonSettingDataType = {
  value: string; //ボタンのバリュー
  link: string; //リンク
  isDisplayed: boolean; //ボタンを表示させるかどうか
  isWebView: boolean; //リンク先をwebviewで開くかどうか
} | null;

export function convertToMainButtonSettingData(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): MainButtonSettingType {
  const returnData: MainButtonSettingDataType = {
    value: data.value,
    link: data.link,
    isDisplayed: data.isDisplayed,
    isWebView: data.isWebView
  };
  return {
    ref,
    data: returnData
  };
}
