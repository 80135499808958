import firebase from "firebase/app";

import { schoolCollectionKey } from "./school";

export const groupCollectionKey = "groups";

export type GroupJoin = "&&" | "||";

export type FilterType =
  | ">="
  | "<="
  | "=="
  | "include"
  | "in"
  | "not-in"
  | "include-in"
  | "include-not-in";

export type GroupFilter = {
  key: string; // 対象とする data column
  type: FilterType;
  value: string | number;
};

export type Group = {
  ref: firebase.firestore.DocumentReference;
  data: GroupData;
};

export type GroupData = {
  title: string;
  filters: GroupFilter[]; // index = 0 から順番にグループ作成していくイメージ
  join: GroupJoin;
  students: firebase.firestore.DocumentReference[];
  result: firebase.firestore.DocumentReference[]; // groupの条件の算出結果としてのgroup対象生徒一覧
  mtelHost?: string;
};

// group reference の [schoolDocId, groupDocId] を返す
export function getDocIdsOfGroupRef(
  groupRef: firebase.firestore.DocumentReference
): [string, string] {
  if (groupRef.parent.id !== groupCollectionKey) {
    throw new Error("given data is not group reference");
  }

  if (
    !groupRef.parent.parent ||
    groupRef.parent.parent.parent.id !== schoolCollectionKey
  ) {
    return ["", groupRef.id];
  }
  const schoolRef = groupRef.parent.parent;
  return [schoolRef.id, groupRef.id];
}

// group の [schoolDocId, groupDocId] を返す
export function getDocIdsOfGroup(group: Group): [string, string] {
  return getDocIdsOfGroupRef(group.ref);
}

export function convertToGroup(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Group {
  const returnData: GroupData = {
    title: data.title,
    filters: data.filters,
    join: data.join,
    students: data.students,
    result: data.result,
    mtelHost: data.mtelHost ?? ""
  };

  return {
    ref,
    data: returnData
  };
}
