import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")
  const _component_m_user_modal = _resolveComponent("m-user-modal")
  const _component_ms_add_message_template_modal = _resolveComponent("ms-add-message-template-modal")
  const _component_ms_edit_message_template_modal = _resolveComponent("ms-edit-message-template-modal")
  const _component_MsTutorPasswordEditModal = _resolveComponent("MsTutorPasswordEditModal")
  const _component_MLoadingOverlay = _resolveComponent("MLoadingOverlay")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    (_ctx.isOpenUserModal)
      ? (_openBlock(), _createBlock(_component_m_user_modal, {
          key: 0,
          onClose: _ctx.closeUserModal
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true),
    (_ctx.isOpenAddMessageTemplateModal)
      ? (_openBlock(), _createBlock(_component_ms_add_message_template_modal, {
          key: 1,
          "default-content": _ctx.defaultMessageContent,
          onClose: _ctx.closeAddMessageTemplateModal
        }, null, 8, ["default-content", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.isOpenEditMessageTemplateModal && _ctx.selectedMessageTemplate)
      ? (_openBlock(), _createBlock(_component_ms_edit_message_template_modal, {
          key: 2,
          "message-template": _ctx.selectedMessageTemplate,
          onClose: _ctx.closeEditMessageTemplateModal
        }, null, 8, ["message-template", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.tutorPasswordChangeRequired)
      ? (_openBlock(), _createBlock(_component_MsTutorPasswordEditModal, { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_MLoadingOverlay, { key: 4 }))
      : _createCommentVNode("", true)
  ], 64))
}