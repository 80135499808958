import firebase from "firebase/app";

export const badgeCollectionKey = "badges";
export const hasBadgeCollectionKey = "hasBadges";

export type BadgeType =
  | "first_join"
  | "weekend_join"
  | "weekly_rank"
  | "monthly_rank"
  | "total_time"
  | "total_join"
  | "every_day_join"
  | "every_week_join";

export type Badge = {
  ref: firebase.firestore.DocumentReference;
  data: BadgeData;
};

export type BadgeData = {
  type: BadgeType; // バッジの種類, バッジ取得基準データの種類判別等に利用 | enum string
  order: number; // バッジの表示上の順番
  name: string; // バッジの名前
  blankImage: string; // 取得前の時のバッジ画像
  image: string; // 取得済の時のバッジ画像 (レベルがある場合は不使用)
  thumbnail: string; // 通知メッセージ用画像 (レベルがある場合は不使用)
  description: string; // 取得済のバッジ詳細説明
  placeholder: string; // 取得前のバッジ詳細説明
  steps: number[]; // レベルがある場合、各レベル達成の基準値一覧 (段階はあるが達成基準が明確でない場合は値が -1)
  stepsBadgeImage: string[]; // 各レベルの時の画像ファイルへのURL
  stepsBadgeThumbnail: string[]; // 各レベルの時の通知メッセージ用画像ファイルへのURL
  stepsLabel: string[]; // 各レベルのラベル ... 達成に必要な回数の数字等
  stepsDescription: string[]; // 各レベルの時のバッジ詳細説明
  stepsTitle: string[]; // 各レベルのタイトル ... レベル1 といった現在のレベル
  stepsBadgeColors: string[]; // 各レベルのバッジカラー
  onboardingButtonText?: string; // バッジ取得前の方法説明用のボタンに表示するテキスト
  onboardingText?: string; // バッジ取得前の方法説明用の説明文テキスト
  onboardingUrl?: string; // バッジ取得前の方法説明用の遷移先画面へのURL
};

export type HasBadge = {
  ref: firebase.firestore.DocumentReference;
  data: HasBadgeData;
};

export type HasBadgeData = {
  badge: firebase.firestore.DocumentReference; // 取得したバッジへのリンク
  stepIndex: number; // レベルがある場合は現在取得済のレベル, レベルが存在しない場合は -1 (JSで0がfalseと判断されるための回避処置)
  transfer?: boolean; // 生徒の教室移動時に作成されたデータかどうか
};

export function getStudentRefFromHasBadgeRef(
  hasBadgeRef: firebase.firestore.DocumentReference
): firebase.firestore.DocumentReference {
  if (
    hasBadgeRef.parent.id !== hasBadgeCollectionKey ||
    !hasBadgeRef.parent.parent
  ) {
    throw new Error("given data is not hasBadge reference");
  }
  return hasBadgeRef.parent.parent;
}

export function convertToBadge(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Badge {
  const returnData: BadgeData = {
    type: data.type,
    order: data.order,
    name: data.name,
    blankImage: data.blankImage,
    image: data.image,
    thumbnail: data.thumbnail,
    description: data.description,
    placeholder: data.placeholder,
    steps: data.steps,
    stepsBadgeImage: data.stepsBadgeImage,
    stepsBadgeThumbnail: data.stepsBadgeThumbnail,
    stepsLabel: data.stepsLabel,
    stepsDescription: data.stepsDescription,
    stepsTitle: data.stepsTitle,
    stepsBadgeColors: data.stepsBadgeColors,
    onboardingButtonText: data.onboardingButtonText,
    onboardingText: data.onboardingText,
    onboardingUrl: data.onboardingUrl
  };

  return {
    ref,
    data: returnData
  };
}

export function convertToHasBadge(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): HasBadge {
  const returnData: HasBadgeData = {
    badge: data.badge,
    stepIndex: data.stepIndex,
    transfer: data.transfer
  };

  return {
    ref,
    data: returnData
  };
}
