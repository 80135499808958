import {
  submissionMasterCollectionKey,
  SubmissionMasterData,
  SubmissionMaster,
  convertToSubmissionMaster,
  RelatedLink,
  isExternalFileUrl
} from "@/entities/submission_master";
import { schoolCollectionKey } from "@/entities/school";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import store from "@/store";

export async function createSubmissionMaster(
  schoolDocId: string,
  name: string,
  label: string,
  studentRefs: firebase.firestore.DocumentReference[],
  closedAt: number,
  submittableFrom: number,
  displayEndAt: number,
  classroomIds: string[],
  description: string,
  relatedLinks: RelatedLink[],
  willSendMessageForUnsubmitted: boolean,
  deleted: boolean
): Promise<void> {
  const data: SubmissionMasterData = {
    name,
    label,
    studentRefs,
    submittableFrom,
    displayEndAt,
    closedAt,
    classroomIds,
    description,
    relatedLinks,
    willSendMessageForUnsubmitted,
    deleted
  };

  await firebase
    .firestore()
    .collection(schoolCollectionKey)
    .doc(schoolDocId)
    .collection(submissionMasterCollectionKey)
    .add(data);
}

export async function getSubmissionMasterByClassroomIds(
  schoolId: string,
  classroomsIds: string[],
  term?: { start: number; end: number }
): Promise<SubmissionMaster[]> {
  const snapshot = await firebase
    .firestore()
    .collection(schoolCollectionKey)
    .doc(schoolId)
    .collection(submissionMasterCollectionKey)
    .where("deleted", "==", false)
    .get();
  if (snapshot.empty) {
    return [];
  }

  const filteredData = snapshot.docs.filter(doc => {
    const data = doc.data();

    const classroomIdsOfSubmissionMaster = data.classroomIds as string[]; // SubmissionMasterを管轄するclassroomIdを取得
    const classroomIdsIncludedInBoth = classroomIdsOfSubmissionMaster.filter(
      (id: string) => classroomsIds.includes(id)
    ); // 先生が管轄するclassroomとSubmissionMasterを管轄するclassroomの共通項を取得
    const isHomeroom = classroomIdsIncludedInBoth.length !== 0; // 共通するclassroomがなければ、管轄外のsubmissionMaserなので、falseを返却

    const isFilteredTerm =
      !term || term.start === 0 || term.end === 0
        ? true
        : data.closedAt > term.start && data.closedAt < term.end;
    return isHomeroom && isFilteredTerm;
  });

  return filteredData.map(doc =>
    convertToSubmissionMaster(doc.data(), doc.ref)
  );
}

export async function updateSubmissionMaster(
  ref: firebase.firestore.DocumentReference,
  data: SubmissionMasterData
): Promise<void> {
  await ref.update(data);
}

/*
  複数の提出物マスタの添付ファイルになっているかを調べる。
  なっていれば、そのファイルの実体は削除すべきでない（= protected）
*/
export const checkIsFileShouldProtected = (
  urlToCheck: string,
  masterRefToCheck?: firebase.firestore.DocumentReference
) => {
  const urlList = store.getters["submissionMaster/uploadedFileUsages"]
    .filter(
      ({ masterRef }: { masterRef: firebase.firestore.DocumentReference }) => {
        return masterRefToCheck ? masterRef.id !== masterRefToCheck?.id : true;
      }
    )
    .map(({ url }: { url: string }) => url);

  const _urlToCheck = urlToCheck.split("?")[0];
  return urlList.includes(_urlToCheck);
};

export async function deleteSubmissionMaster(
  ref: firebase.firestore.DocumentReference
): Promise<void> {
  // 生徒用提出物削除処理
  const submissionSnapshot = await firebase
    .firestore()
    .collectionGroup("submissions")
    .where("submissionMasterRef", "==", ref)
    .get();
  submissionSnapshot.docs.map(async doc => {
    doc.data().imageUrls.map(async (imageUrl: string) => {
      try {
        // 提出画像の削除
        await firebase.storage().refFromURL(imageUrl).delete();
      } catch (e) {
        // Exception Ignored
      }
    });
    // 生徒用提出物の削除
    await doc.ref.delete();
  });
  // 提出物マスター削除処理
  const snapshot = await ref.get();
  const data = snapshot.data();
  if (!snapshot.exists || !data) {
    throw new Error("no submission master found"); // 通常ありえない
  }
  if (data.relatedLinks) {
    // この提出物マスタだけの添付ファイルである場合に限り、ファイルを削除する

    await Promise.all(
      data.relatedLinks
        .filter(({ url }: { url: string }) => !isExternalFileUrl(url))
        .map(async ({ url }: { url: string }) => {
          if (!checkIsFileShouldProtected(url, ref)) {
            try {
              await firebase.storage().refFromURL(url).delete();
            } catch (e) {
              // Exception Ignored
            }
          }
        })
    );
  }
  await ref.delete();
}
