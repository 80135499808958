import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = {
  key: 1,
  class: "flex-grow bg-secondary-100 rounded-md border text-base outline-none text-gray-400 py-1 px-3 leading-8 whitespace-nowrap overflow-hidden"
}
const _hoisted_3 = {
  key: 2,
  class: "flex w-full items-center"
}
const _hoisted_4 = { class: "ml-2 text-sm font-medium" }
const _hoisted_5 = {
  key: 4,
  class: "mt-1 text-red-500 text-xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createBlock("label", {
          key: 0,
          for: _ctx.name,
          class: "leading-7 text-sm font-medium text-gray-500"
        }, _toDisplayString(_ctx.label), 9, ["for"]))
      : _createCommentVNode("", true),
    (_ctx.disabled)
      ? (_openBlock(), _createBlock("div", _hoisted_2, _toDisplayString(_ctx.value), 1))
      : (_ctx.suffix)
        ? (_openBlock(), _createBlock("div", _hoisted_3, [
            _createVNode("input", {
              type: _ctx.type,
              name: _ctx.name,
              placeholder: _ctx.placeholder,
              value: _ctx.value,
              class: ["flex-grow bg-primary-100 rounded-md border focus:border-primary-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-300", _ctx.dynamicClasses],
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('input', $event.target.value))),
              onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
            }, null, 42, ["type", "name", "placeholder", "value"]),
            _createVNode("span", _hoisted_4, _toDisplayString(_ctx.suffix), 1)
          ]))
        : (_openBlock(), _createBlock("input", {
            key: 3,
            type: _ctx.type,
            name: _ctx.name,
            placeholder: _ctx.placeholder,
            value: _ctx.value,
            class: ["w-full bg-primary-100 rounded-md border focus:border-primary-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-300", _ctx.dynamicClasses],
            onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('input', $event.target.value))),
            onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
          }, null, 42, ["type", "name", "placeholder", "value"])),
    (_ctx.invalid && _ctx.entered && _ctx.invalidMessage)
      ? (_openBlock(), _createBlock("p", _hoisted_5, _toDisplayString(_ctx.invalidMessage), 1))
      : _createCommentVNode("", true)
  ]))
}