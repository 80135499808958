
import { Options, Vue } from "vue-class-component";
import MLoadingIcon from "@/components/MLoadingIcon.vue";
import store from "@/store";

@Options({
  components: {
    MLoadingIcon
  }
})
export default class MLoadingOverlay extends Vue {
  get loadText(): string {
    return store.state.loadText;
  }
}
