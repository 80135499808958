import { convertToLabel, Label, labelCollectionKey } from "@/entities/label";
import firebase from "firebase/app";

export async function fetchRoomLabels(
  schoolRef?: firebase.firestore.DocumentReference
): Promise<Label[]> {
  const snapshot = schoolRef
    ? await schoolRef
        .collection(labelCollectionKey)
        .where("type", "==", "room")
        .get()
    : await firebase
        .firestore()
        .collection(labelCollectionKey)
        .where("type", "==", "room")
        .get();

  if (snapshot.empty) {
    return [];
  }

  const labels = snapshot.docs.map(doc => convertToLabel(doc.data(), doc.ref));
  labels.sort((a, b) => a.data.order - b.data.order);

  return labels;
}

export async function fetchLabel(
  ref: firebase.firestore.DocumentReference
): Promise<Label | null> {
  const snapshot = await ref.get();
  const data = snapshot.data();
  if (!snapshot.exists || !data) {
    return null;
  }
  return convertToLabel(data, snapshot.ref);
}

export async function fetchAllLabels(): Promise<Label[]> {
  const snapshot = await firebase
    .firestore()
    .collection(labelCollectionKey)
    .get();
  if (snapshot.empty) {
    return [];
  }

  const labels = snapshot.docs.map(doc => convertToLabel(doc.data(), doc.ref));
  labels.sort((a, b) => a.data.order - b.data.order);

  return labels;
}
