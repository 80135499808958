import { classroomCollectionKey } from "@/entities/classroom";
import {
  convertToLearning,
  Learning,
  LearningData,
  learningCollectionKey
} from "@/entities/learning";
import { schoolCollectionKey } from "@/entities/school";
import { studentCollectionKey } from "@/entities/student";
import firebase from "firebase/app";
import "firebase/firestore";

export function getLearningRef(
  schoolId: string,
  classroomId: string,
  studentId: string,
  learningId: string
): firebase.firestore.DocumentReference {
  return firebase
    .firestore()
    .collection(schoolCollectionKey)
    .doc(schoolId)
    .collection(classroomCollectionKey)
    .doc(classroomId)
    .collection(studentCollectionKey)
    .doc(studentId)
    .collection(learningCollectionKey)
    .doc(learningId);
}

export async function getLearningsOf(
  studentRef: firebase.firestore.DocumentReference,
  timestamp = 0,
  limit = 0,
  offsetRef?: firebase.firestore.DocumentReference
): Promise<Learning[]> {
  let query = studentRef
    .collection(learningCollectionKey)
    .where("timestamp", ">=", timestamp)
    .orderBy("timestamp", "desc");
  if (limit > 0) {
    query = query.limit(limit);
  }
  if (offsetRef) {
    const offsetSnapshot = await offsetRef.get();
    query = query.startAfter(offsetSnapshot);
  }

  const learningsSnapshot = await query.get();
  if (learningsSnapshot.empty) {
    return [];
  }

  return learningsSnapshot.docs
    .map(doc => convertToLearning(doc.data(), doc.ref))
    .filter(learning => learning.data.totalTime > 0); // zoom起動後に入室しなかった場合に残る learning を除く
}

export async function getLearning(
  ref: firebase.firestore.DocumentReference
): Promise<Learning | null> {
  const snapshot = await ref.get();
  const data = snapshot.data();
  if (!snapshot.exists || !data) {
    console.error("Failed to get learning data");
    return null;
  }
  return convertToLearning(data, snapshot.ref);
}

export async function addLearning(
  ref: firebase.firestore.DocumentReference,
  learningData: LearningData
): Promise<
  firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
> {
  return await ref.collection(learningCollectionKey).add(learningData);
}

export async function deleteLearning(
  ref: firebase.firestore.DocumentReference
) {
  await ref.delete();
}

export async function updateLearning(
  ref: firebase.firestore.DocumentReference,
  learningData: LearningData,
  isManuallyUpdated = false
) {
  await ref.update(
    isManuallyUpdated
      ? { ...learningData, manuallyUpdated: true }
      : learningData
  );
}

export async function updateLatestLearing(
  learningRef: firebase.firestore.DocumentReference
) {
  const refPath: string = learningRef.path;
  const updateLatstLearing = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("update_latest_learning");
  const res = await updateLatstLearing({ learningPath: refPath });
  return res.data;
}
