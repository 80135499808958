import firebase from "firebase/app";

export const roomRegularlyReservationCollectionKey =
  "roomRegularlyReservations";
  export const roomRegularlyReservationOfPopyCollectionKey =
  "roomRegularlyReservationsOfPopy";

export type RoomRegularlyReservation = {
  ref: firebase.firestore.DocumentReference;
  data: RoomRegularlyReservationData;
};

export type RoomRegularlyReservationData = {
  name: string; // 開室予定の学習室名
  labels: firebase.firestore.DocumentReference[];
  target?: firebase.firestore.DocumentReference; // 学習室の対象領域
  group?: firebase.firestore.DocumentReference; // 学習室の対象グループ
  groupName: string;
  withNotification: boolean; // 開室通知の有無
  nextTime: number; // 次回送信予定時間
  reservationType: "day" | "week" | "month";
  time: string; // 通知時間(HH:mm)
  end: string;
  weekday?: number; // 曜日(type == "week" の場合のみ) / 0 ~ 6
  monthday?: number; // 月内の日付(type == "month" の場合のみ)
  deletionTime?: number; // 削除予定時間
  mtelHost?: string;
};

export type RoomRegularlyReservationOfPopy = {
  ref: firebase.firestore.DocumentReference;
  data: RoomRegularlyReservationOfPopyData;
};


export type RoomRegularlyReservationOfPopyData = {
  name: string; // 開室予定の学習室名
  labels: firebase.firestore.DocumentReference[];
  target?: firebase.firestore.DocumentReference; // 学習室の対象領域
  group?: firebase.firestore.DocumentReference; // 学習室の対象グループ
  groupName: string;
  withNotification: boolean; // 開室通知の有無
  nextTime: number; // 次回送信予定時間
  reservationType: "day" | "week" | "month";
  time: string; // 通知時間(HH:mm)
  end: string;
  openingVideo: string,
  closingVideo: string,
  weekday?: number; // 曜日(type == "week" の場合のみ) / 0 ~ 6
  monthday?: number; // 月内の日付(type == "month" の場合のみ)
  deletionTime?: number; // 削除予定時間
  mtelHost?: string;
};

export function convertToRoomRegularlyReservation(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): RoomRegularlyReservation {
  const returnData: RoomRegularlyReservationData = {
    name: data.name,
    labels: data.labels,
    groupName: data.groupName,
    withNotification: data.withNotification,
    nextTime: data.nextTime,
    reservationType: data.reservationType,
    time: data.time,
    end: data.end
  };

  if (data.target) {
    returnData.target = data.target;
  }

  if (data.group) {
    returnData.group = data.group;
  }

  if (data.weekday !== undefined) {
    returnData.weekday = data.weekday;
  }

  if (data.monthday !== undefined) {
    returnData.monthday = data.monthday;
  }

  if (data.deletionTime) {
    returnData.deletionTime = data.deletionTime;
  }

  if (data.mtelHost) {
    returnData.mtelHost = data.mtelHost;
  }

  return {
    ref,
    data: returnData
  };
}

export function convertToRoomRegularlyReservationOfPopy(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): RoomRegularlyReservationOfPopy {
  const returnData: RoomRegularlyReservationOfPopyData = {
    name: data.name,
    labels: data.labels,
    groupName: data.groupName,
    withNotification: data.withNotification,
    nextTime: data.nextTime,
    reservationType: data.reservationType,
    time: data.time,
    end: data.end,
    openingVideo: data.openingVideo,
    closingVideo: data.closingVideo
  };

  if (data.target) {
    returnData.target = data.target;
  }

  if (data.group) {
    returnData.group = data.group;
  }

  if (data.weekday !== undefined) {
    returnData.weekday = data.weekday;
  }

  if (data.monthday !== undefined) {
    returnData.monthday = data.monthday;
  }

  if (data.deletionTime) {
    returnData.deletionTime = data.deletionTime;
  }

  if (data.mtelHost) {
    returnData.mtelHost = data.mtelHost;
  }

  return {
    ref,
    data: returnData
  };
}