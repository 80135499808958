
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import MTextField from "@/components/form/MTextField.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { deleteMessageTemplate, updateMessageTemplate } from "@/api/message";
import { MessageTemplate } from "@/entities/message_template";
import { saveErrorLog } from "@/api/error";

@Options({
  components: {
    MBaseModal,
    MButton,
    MTextArea,
    MTextField
  },
  emits: ["close"],
  props: {
    messageTemplate: Object
  }
})
export default class MsEditMessageTemplateModal extends Vue {
  messageTemplate!: MessageTemplate;
  heading = "";
  content = "";

  get validHeading() {
    return this.heading.length > 0;
  }

  get validContent() {
    return this.content.length > 0;
  }

  get validData() {
    return this.validHeading && this.validContent;
  }

  async deleteMessageTemplate() {
    store.commit("START_LOADING", "削除中...");

    try {
      await deleteMessageTemplate(this.messageTemplate.ref);
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(`定型メッセージの削除に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to delete message template"
      );
    }

    this.$router.go(0);
  }

  async updateMessageTemplate() {
    if (!this.validData || !store.state.role) {
      return;
    }

    store.commit("START_LOADING", "更新中...");

    try {
      await updateMessageTemplate(this.messageTemplate.ref, {
        content: this.content,
        heading: this.heading
      });
    } catch (e) {
      store.commit("END_LOADING");
      alert(`定型メッセージの更新に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to update message template"
      );
      return;
    }

    store.commit("END_LOADING");

    this.$router.go(0);
  }

  close() {
    this.$emit("close");
  }

  created() {
    this.content = this.messageTemplate.data.content;
    this.heading = this.messageTemplate.data.heading;
  }
}
