import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import {
  convertToMainButtonSettingData,
  MainButtonSettingType
} from "@/entities/main_button_setting";

const firebaseCollectionName = "mainButtonSetting";

export async function getMainButtonSetting(): Promise<MainButtonSettingType[]> {
  const db = firebase.firestore();
  const snapshot = await db.collection(firebaseCollectionName).get();
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc =>
    convertToMainButtonSettingData(doc.data(), doc.ref)
  );
}

export async function getMainButtonSettingOfSchool(
  ref: firebase.firestore.DocumentReference
): Promise<MainButtonSettingType[]> {
  const snapshot = await ref.collection(firebaseCollectionName).get();
  // 空っぽだったらまずはアドミンのminiAppsを踏襲する
  if (snapshot.empty) {
    return await getMainButtonSetting();
  }
  return snapshot.docs.map(doc =>
    convertToMainButtonSettingData(doc.data(), doc.ref)
  );
}
