import firebase from "firebase/app";

export const errorLogCollectionKey = "errorLogs";

export type ErrorLog = {
  ref: firebase.firestore.DocumentReference;
  data: ErrorLogData;
};

export type ErrorLogData = {
  uid: string;
  code: string;
  message: string;
  summary: string;
  timestamp: number;
};

export function convertToErrorLog(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): ErrorLog {
  const returnData: ErrorLogData = {
    uid: data.uid,
    code: data.code,
    message: data.message,
    summary: data.summary,
    timestamp: data.timestamp
  };

  return {
    ref,
    data: returnData
  };
}
