import firebase from "firebase/app";

export const submissionMasterCollectionKey = "submissionMasters";

/*
    Tutorがあるグループに対して設定する
    提出物マスターデータのドキュメント。
*/
export type SubmissionMaster = {
  ref: firebase.firestore.DocumentReference;
  data: SubmissionMasterData;
};

export type RelatedLink = {
  label: string;
  url: string;
};

export type SubmissionMasterData = {
  name: string; // 提出物名
  label: string; // 管理用のラベル（生徒側には表示されない）
  description?: string; // 備考
  relatedLinks?: RelatedLink[];
  groupRef?: firebase.firestore.DocumentReference; // 対象のグループ
  studentRefs: firebase.firestore.DocumentReference[]; // 個別に指定する生徒
  submittableFrom: number; // 生徒側に表示される unixTime
  displayEndAt?: number; // 表示終了日(生徒画面で表示されなくなる日) unixtime
  closedAt: number; // 締切 unixTime
  classroomIds: string[]; // classroomIdの配列
  willSendMessageForUnsubmitted?: boolean; // 未提出の生徒に通知を送るかどうか
  deleted: boolean; // 論理削除フラグ (デフォルトはfalse)
};

export const convertToSubmissionMasterData = (
  data: firebase.firestore.DocumentData
) => {
  const result = {
    name: data.name,
    label: data.label,
    description: data.description ?? "",
    relatedLinks: data.relatedLinks ?? [],
    studentRefs: data.studentRefs ?? [],
    submittableFrom: data.submittableFrom,
    displayEndAt: data.displayEndAt ?? 0,
    closedAt: data.closedAt,
    classroomIds: data.classroomIds,
    willSendMessageForUnsubmitted: data.willSendMessageForUnsubmitted !== false, // undefined は true とする
    deleted: data.deleted
  } as SubmissionMasterData;
  if (data.groupRef) result.groupRef = data.groupRef;
  return result;
};

export const convertToSubmissionMaster = (
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): SubmissionMaster => {
  return {
    ref,
    data: convertToSubmissionMasterData(data)
  } as SubmissionMaster;
};

// その他提出ファイルをアップロード中のメタデータ
export type TaskSummary = {
  bytesTransferred: number;
  totalBytes: number;
  transferredPercentage: number;
};

export function isExternalFileUrl(url: string): boolean {
  return (
    !url.includes("https://firebasestorage.googleapis.com") ||
    !url.includes("mingaku")
  );
}
