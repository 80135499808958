import {
  convertToTarget,
  Target,
  targetCollectionKey
} from "@/entities/target";
import firebase from "firebase/app";

export async function fetchTargets(): Promise<Target[]> {
  const db = firebase.firestore();
  const snapshot = await db.collection(targetCollectionKey).get();
  if (snapshot.empty) {
    return [];
  }

  return snapshot.docs.map(doc => convertToTarget(doc.data(), doc.ref));
}
