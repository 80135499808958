import firebase from "firebase/app";

export const serviceTopicCollectionKey = "serviceTopics";

export type ServiceTopic = {
  ref: firebase.firestore.DocumentReference;
  data: ServiceTopicData;
};

export type ServiceTopicData = {
  url: string;
  label: string;
  timestamp: number;
};

export function convertToServiceTopic(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): ServiceTopic {
  const returnData: ServiceTopicData = {
    url: data.url,
    label: data.label,
    timestamp: data.timestamp
  };
  return {
    ref,
    data: returnData
  };
}
