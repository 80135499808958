import firebase from "firebase/app";

export const messageEditorCollectionKey = "messageEditors";

export type MessageEditor = {
  ref: firebase.firestore.DocumentReference;
  data: MessageEditorData;
};

export type MessageEditorData = {
  user: firebase.firestore.DocumentReference;
  timestamp: number;
};

export function convertToMessageEditor(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): MessageEditor {
  const returnData: MessageEditorData = {
    user: data.user,
    timestamp: data.timestamp
  };

  return {
    ref,
    data: returnData
  };
}
