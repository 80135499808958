import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import {
  QandaChatHistory,
  QandaChatHistoryData,
  QandaMessage
} from "@/entities/qanda";
import { Student } from "@/entities/student";
import store from "@/store";

export function validateQandaAPIResponse(
  res: firebase.functions.HttpsCallableResult
) {
  if (res.data.status === "success") {
    return;
  } else if (res.data.status === "error") {
    throw new Error(res.data.errorMsg);
  } else {
    throw new Error("予期せぬエラーが発生しました。");
  }
}

export async function getQandaMessages(
  student: Student
): Promise<QandaChatHistory> {
  const qandaId = student.data.qandaUserId ?? null;

  if (!qandaId) {
    alert(
      "QANDA IDが見つかりません。この生徒がQANDAに連携してあることを確認してください。"
    );
    return null;
  }

  if (!student.data.isLinkedToQanda) {
    alert("この生徒はQANDAとの連携が解除されています。");
    return null;
  }

  const getQandaHistory = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("get_qanda_history");

  const types = ["QNA", "PROBLEM_SOLVER", "ENGLISH_TUTOR"];
  const promises = types.map(type => getQandaHistory({ qandaId, type }));
  const responses = await Promise.all(promises);

  const data = responses.reduce((acc: QandaChatHistoryData[], res) => {
    validateQandaAPIResponse(res);
    const chatHistory = res.data.res;
    if (!chatHistory.histories || chatHistory.histories.length === 0)
      return acc;
    chatHistory.histories.forEach(
      (h: { id: string; createTime: string; messages: QandaMessage[] }) => {
        acc.push({ ...h, type: res.data.type });
      }
    );
    return acc;
  }, []);

  return {
    studentUid: student.ref.id,
    qandaId: student.data.qandaUserId ?? "",
    data
  };
}

export function extractStudent(students: Student[], studentId: string) {
  let student: Student | null;
  const matchStudents = students.filter(s => s.ref.id === studentId);
  if (!matchStudents || matchStudents.length === 0) {
    student = null;
  } else {
    student = matchStudents[0];
  }

  if (!student) {
    alert("生徒情報が取得できません");
    throw new Error("生徒情報が見つかりません");
  }

  return student;
}

export async function getStudentAndQandaChatHistory(studentId: string) {
  await store.dispatch("getStudents");
  const student = extractStudent(store.state.students, studentId);

  await store.dispatch("getQandaQuestions", student);

  return { student, qandaChatHistory: store.state.qandaChatHistory };
}
