import firebase from "firebase/app";

export const classroomCollectionKey = "classrooms";

export type Classroom = {
  ref: firebase.firestore.DocumentReference;
  data: ClassroomData;
};

export type ClassroomData = {
  id: string; // 教室管理用ID (同塾内 unique)
  name: string; // 教室名
  postalCode: string; // 郵便番号
  address: string; // 住所
  tutors: string[]; // 教室のアクセス権限を持つスタッフの uid 一覧
  studentCount: number; // 教室に所属する生徒数
};

export function getSchoolRefFromClassroomRef(
  classroomRef: firebase.firestore.DocumentReference
): firebase.firestore.DocumentReference {
  if (
    classroomRef.parent.id !== classroomCollectionKey ||
    !classroomRef.parent.parent
  ) {
    throw new Error("given data is not classroom reference");
  }
  return classroomRef.parent.parent;
}

// classroom reference の [schoolDocId, classroomDocId] を返す
export function getDocIdsOfClassroomRef(
  classroomRef: firebase.firestore.DocumentReference
): [string, string] {
  if (classroomRef.parent.id !== classroomCollectionKey) {
    throw new Error("given data is not classroom reference");
  }

  if (!classroomRef.parent.parent) {
    return ["", ""];
  }
  const schoolRef = classroomRef.parent.parent;
  return [schoolRef.id, classroomRef.id];
}

// classroom の [schoolDocId, classroomDocId] を返す
export function getDocIdsOfClassroom(classroom: Classroom): [string, string] {
  return getDocIdsOfClassroomRef(classroom.ref);
}

export function convertToClassroom(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Classroom {
  const returnData: ClassroomData = {
    id: data.id,
    name: data.name,
    postalCode: data.postalCode,
    address: data.address,
    tutors: data.tutors,
    studentCount: data.studentCount
  };

  return {
    ref,
    data: returnData
  };
}
