import firebase from "firebase/app";

export const messageTemplateCollectionKey = "messageTemplates";

export type MessageTemplate = {
  ref: firebase.firestore.DocumentReference;
  data: MessageTemplateData;
};

export type MessageTemplateData = {
  heading: string;
  content: string;
  usedCount: number;
};

export function convertToMessageTemplate(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): MessageTemplate {
  const returnData: MessageTemplateData = {
    heading: data.heading,
    content: data.content,
    usedCount: data.usedCount
  };

  return {
    ref,
    data: returnData
  };
}
