// MessageTemplateSheet 用のvuex

import { saveErrorLog } from "@/api/error";
import {
  fetchCommonMessageTemplates,
  fetchMessageTemplatesOf
} from "@/api/message";
import { MessageTemplate } from "@/entities/message_template";
import { Module } from "vuex";
import { RootState } from "..";

interface MessageTemplateSheetState {
  initialized: boolean;
  load: boolean;
  isOpenAddMessageTemplateModal: boolean;
  isOpenEditMessageTemplateModal: boolean;
  selectedMessageTemplate: MessageTemplate | null;
  defaultMessageContent: string;
  commomMessageTemplates: MessageTemplate[];
  ownMessageTemplates: MessageTemplate[];
}

const messageTemplateSheetModule: Module<MessageTemplateSheetState, RootState> =
  {
    namespaced: true,

    state: {
      initialized: false,
      load: true,
      isOpenAddMessageTemplateModal: false,
      isOpenEditMessageTemplateModal: false,
      selectedMessageTemplate: null,
      defaultMessageContent: "",
      commomMessageTemplates: [],
      ownMessageTemplates: []
    },

    getters: {},

    mutations: {
      INITIALIZE(state) {
        state.initialized = true;
      },
      SET_LOAD(state, load: boolean) {
        state.load = load;
      },
      SET_IS_OPEN_ADD_MESSAGE_TEMPLATE_MODAL(state, isOpen: boolean) {
        state.isOpenAddMessageTemplateModal = isOpen;
      },
      SET_IS_OPEN_EDIT_MESSAGE_TEMPLATE_MODAL(state, isOpen: boolean) {
        state.isOpenEditMessageTemplateModal = isOpen;
      },
      SET_SELECTED_MESSAGE_TEMPLATE(state, messageTemplate: MessageTemplate) {
        state.selectedMessageTemplate = messageTemplate;
      },
      SET_DEFAULT_MESSAGE_CONTENT(state, content: string) {
        state.defaultMessageContent = content;
      },
      SET_COMMON_MESSAGE_TEMPLATES(state, templates: MessageTemplate[]) {
        state.commomMessageTemplates = templates;
      },
      SET_OWN_MESSAGE_TEMPLATES(state, templates: MessageTemplate[]) {
        state.ownMessageTemplates = templates;
      },
      CLEAR(state) {
        state.initialized = false;
        state.load = true;
        state.isOpenAddMessageTemplateModal = false;
        state.isOpenEditMessageTemplateModal = false;
        state.selectedMessageTemplate = null;
        state.defaultMessageContent = "";
        state.commomMessageTemplates = [];
        state.ownMessageTemplates = [];
      }
    },

    actions: {
      async setup(context) {
        if (context.state.initialized) {
          return;
        }

        context.commit("INITIALIZE");
        await context.dispatch("getMessageTemplates");
        context.commit("SET_LOAD", false);
      },
      async getMessageTemplates(context) {
        if (!context.rootState.role) {
          return;
        }

        try {
          const [commonMessageTemplates, ownMessageTemplates] =
            await Promise.all([
              fetchCommonMessageTemplates(),
              fetchMessageTemplatesOf(context.rootState.role.data.ref)
            ]);
          context.commit(
            "SET_COMMON_MESSAGE_TEMPLATES",
            commonMessageTemplates
          );
          context.commit("SET_OWN_MESSAGE_TEMPLATES", ownMessageTemplates);
        } catch (e) {
          alert(`定型メッセージ情報の取得に失敗しました\n\n${e}`);
          await saveErrorLog(
            context.rootState.role,
            e.code,
            e.message,
            "Failed to get message templates"
          );
        }
      }
    }
  };

export default messageTemplateSheetModule;
