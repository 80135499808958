import firebase from "firebase/app";

export const roomCollectionKey = "rooms";

export type Room = {
  ref: firebase.firestore.DocumentReference;
  data: RoomData;
};

export type RoomData = {
  name: string;
  uuid: string;
  meetingId: string;
  joinUrl: string;
  hostUrl: string;
  zoomUserId: string;
  start: number;
  end: number;
  labels: firebase.firestore.DocumentReference[];
  target?: firebase.firestore.DocumentReference;
  group?: firebase.firestore.DocumentReference;
  school?: firebase.firestore.DocumentReference;
  classroomIdsOfTutor?: string[];
};

export type RunningRegistrant = {
  id: string;
  start: number;
  studentDocId: string;
};
export type RoomSummaryWithRunningRegistrants = {
  id: string;
  name: string;
  isMingakuRoom: boolean;
  runningRegistrants: RunningRegistrant[];
};

export function convertToRoom(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Room {
  const returnData: RoomData = {
    name: data.name,
    uuid: data.uuid,
    meetingId: data.meetingId,
    joinUrl: data.joinUrl,
    hostUrl: data.hostUrl,
    zoomUserId: data.zoomUserId,
    start: data.start,
    end: data.end,
    labels: data.labels
  };

  if (data.target) {
    returnData.target = data.target;
  }

  if (data.group) {
    returnData.group = data.group;
  }

  if (data.school) {
    returnData.school = data.school;
  }

  if (data.classroomIdsOfTutor) {
    returnData.classroomIdsOfTutor = data.classroomIdsOfTutor;
  }

  return {
    ref,
    data: returnData
  };
}
