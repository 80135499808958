import { createDecorator } from "vue-class-component";
import { mapActions, mapMutations } from "vuex";

export function State(stateType: string, module?: string) {
  return createDecorator((options, key) => {
    if (!options.computed) options.computed = {};
    options.computed[key] = function () {
      return module !== undefined
        ? this.$store.state[module][stateType]
        : this.$store.state[stateType];
    };
  });
}

export function Getter(getterType: string, module?: string) {
  return createDecorator((options, key) => {
    if (!options.computed) options.computed = {};
    options.computed[key] = function () {
      return module !== undefined
        ? this.$store.getters[`${module}/${getterType}`]
        : this.$store.getters[getterType];
    };
  });
}

export function Mutation(mutationType: string, module?: string) {
  return createDecorator(options => {
    if (!options.methods) options.methods = {};

    module !== undefined
      ? mapMutations([`${module}/${mutationType}`])
      : mapMutations([mutationType]);
  });
}

export function Action(actionType: string, module?: string) {
  return createDecorator(options => {
    if (!options.methods) options.methods = {};

    module !== undefined
      ? mapActions([`${module}/${actionType}`])
      : mapActions([actionType]);
  });
}
