
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { tutorUpdatePassword } from "@/api/tutor";

@Options({
  components: {
    MBaseModal,
    MButton,
    MTextField
  }
})
export default class MsPasswordEditModal extends Vue {
  password = "";
  passwordEntered = false;
  get isValidPassword(): boolean {
    return this.password.length >= 8;
  }
  onPasswordInput(val: string) {
    this.password = val;
  }
  onPasswordBlur() {
    this.passwordEntered = true;
  }

  passwordToConfirm = "";
  passwordConfirmEntered = false;
  confirmPasswordErrorType: "mismatch" | "invalidFormat" | "" = "";
  isValidPasswordConfirm = false;
  get confirmPasswordError(): string {
    if (this.confirmPasswordErrorType === "invalidFormat") {
      return "パスワードは8文字以上の英数字・記号で設定してください";
    } else if (this.confirmPasswordErrorType === "mismatch") {
      return "パスワードが一致しません";
    } else {
      return "";
    }
  }
  onConfirmInput(val: string) {
    this.passwordToConfirm = val;
    this.validatePasswordConfirm();
  }
  onConfirmBlur() {
    this.passwordConfirmEntered = true;
    this.validatePasswordConfirm();
  }
  validatePasswordConfirm() {
    if (this.passwordToConfirm.length < 8) {
      this.confirmPasswordErrorType = "invalidFormat";
      this.isValidPasswordConfirm = false;
      return;
    }
    if (this.password !== this.passwordToConfirm) {
      this.confirmPasswordErrorType = "mismatch";
      this.isValidPasswordConfirm = false;
      return;
    }
    this.isValidPasswordConfirm = true;
  }

  get validDataInput(): boolean {
    return this.isValidPassword && this.isValidPasswordConfirm;
  }

  async commit() {
    if (store.state.role?.data.type !== "tutor") return;
    if (!store.state.tutor) return;
    if (!store.state.tutor.config.data.passwordChangeRequired) return;
    if (!this.validDataInput) return;

    try {
      store.commit("START_LOADING", "パスワード更新中...");
      await tutorUpdatePassword(store.state.tutor.main.ref, this.password);
      alert("パスワードを更新しました。再度ログインしてください。");
      store.dispatch("signOut");
    } catch (e) {
      console.error(e);
      alert(
        "パスワードの更新に失敗しました。" + "\n" + e.message ?? "Unknown error"
      );
    } finally {
      store.commit("END_LOADING");
    }
  }
}
