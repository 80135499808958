
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import MTextField from "@/components/form/MTextField.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { registerMessageTemplate } from "@/api/message";
import { saveErrorLog } from "@/api/error";

@Options({
  components: {
    MBaseModal,
    MButton,
    MTextArea,
    MTextField
  },
  emits: ["close"],
  props: {
    defaultContent: String
  }
})
export default class MsAddMessageTemplateModal extends Vue {
  heading = "";
  enteredHeading = false;
  content = "";
  defaultContent = "";

  get validHeading() {
    return this.heading.length > 0;
  }

  get validContent() {
    return this.content.length > 0;
  }

  get validData() {
    return this.validHeading && this.validContent;
  }

  get allEntered() {
    return this.enteredHeading;
  }

  allEnter() {
    this.enteredHeading = true;
  }

  async registerMessageTemplate() {
    this.allEnter();

    if (!this.validData || !store.state.role) {
      return;
    }

    store.commit("START_LOADING", "登録中...");

    try {
      await registerMessageTemplate(
        store.state.role.data.ref,
        this.heading,
        this.content
      );
    } catch (e) {
      store.commit("END_LOADING");
      alert(`メッセージの登録に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to register message template"
      );
      return;
    }

    store.commit("END_LOADING");

    this.$router.go(0);
  }

  close() {
    this.$emit("close");
  }

  created() {
    this.content = this.defaultContent;
  }
}
