import firebase from "firebase/app";

import { schoolCollectionKey } from "./school";

export const labelCollectionKey = "labels";

export type Label = {
  ref: firebase.firestore.DocumentReference;
  data: LabelData;
};

export type LabelData = {
  type: LabelType;
  name: string;
  order: number;
};

export type LabelChoice = {
  name: string;
  id: string;
  selected: boolean;
  order: number;
};

export type LabelType = "room";

// label reference の [schoolDocId, labelDocId] を返す
export function getDocIdsOfLabelRef(
  labelRef: firebase.firestore.DocumentReference
): [string, string] {
  if (labelRef.parent.id !== labelCollectionKey) {
    throw new Error("given data is not label reference");
  }

  if (
    !labelRef.parent.parent ||
    labelRef.parent.parent.parent.id !== schoolCollectionKey
  ) {
    return ["", labelRef.id];
  }
  const schoolRef = labelRef.parent.parent;
  return [schoolRef.id, labelRef.id];
}

// label の [schoolDocId, labelDocId] を返す
export function getDocIdsOfLabel(label: Label): [string, string] {
  return getDocIdsOfLabelRef(label.ref);
}

export function convertToLabel(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Label {
  const returnData: LabelData = {
    type: data.type,
    name: data.name,
    order: data.order
  };

  return {
    ref,
    data: returnData
  };
}
