import firebase from "firebase/app";

export const targetCollectionKey = "targets";

export type Target = {
  ref: firebase.firestore.DocumentReference;
  data: TargetData;
};

export type TargetData = {
  key: string;
  name: string;
};

export function convertToTarget(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Target {
  const returnData: TargetData = {
    key: data.key,
    name: data.name
  };

  return {
    ref,
    data: returnData
  };
}
