import {
  convertToGroup,
  Group,
  groupCollectionKey,
  GroupFilter,
  GroupJoin
} from "@/entities/group";
import { schoolCollectionKey } from "@/entities/school";
import firebase from "firebase/app";
import "firebase/firestore";

export async function fetchGroupsOfSchool(
  schoolRef: firebase.firestore.DocumentReference
): Promise<Group[]> {
  const snapshot = await schoolRef.collection(groupCollectionKey).get();
  if (snapshot.empty) {
    return [];
  }

  return snapshot.docs.map(doc => convertToGroup(doc.data(), doc.ref));
}

export async function fetchGroupsOfAdmin(): Promise<Group[]> {
  const snapshot = await firebase
    .firestore()
    .collection(groupCollectionKey)
    .get();
  if (snapshot.empty) {
    return [];
  }

  return snapshot.docs.map(doc => convertToGroup(doc.data(), doc.ref));
}

export async function registerGroup(
  title: string,
  filters: GroupFilter[],
  join: GroupJoin,
  students: firebase.firestore.DocumentReference[]
): Promise<void> {
  const studentPathes = students.map(student => {
    const classroomRef = student.parent.parent!;
    const schoolRef = classroomRef.parent.parent!;
    return `${schoolRef.id}/${classroomRef.id}/${student.id}`;
  });

  const callRegisterGroup = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("register_group");

  const res = await callRegisterGroup({
    title,
    filters,
    join,
    students: studentPathes
  });
  if (!res.data.success) {
    throw new Error("Unexpected error occured");
  }
}

export async function updateGroup(
  groupId: string,
  title: string,
  filters: GroupFilter[],
  join: GroupJoin,
  students: firebase.firestore.DocumentReference[]
): Promise<void> {
  const studentPathes = students.map(student => {
    const classroomRef = student.parent.parent!;
    const schoolRef = classroomRef.parent.parent!;
    return `${schoolRef.id}/${classroomRef.id}/${student.id}`;
  });

  const callUpdateGroup = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("update_group");

  const res = await callUpdateGroup({
    group: groupId,
    title,
    filters,
    join,
    students: studentPathes
  });
  if (!res.data.success) {
    throw new Error("Unexpected error occured");
  }
}

export async function deleteGroup(
  groupId: string,
  schoolId?: string
): Promise<void> {
  if (schoolId) {
    await firebase
      .firestore()
      .collection(schoolCollectionKey)
      .doc(schoolId)
      .collection(groupCollectionKey)
      .doc(groupId)
      .delete();
  } else {
    await firebase
      .firestore()
      .collection(groupCollectionKey)
      .doc(groupId)
      .delete();
  }
}
