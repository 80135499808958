
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["close"],
  props: {
    size: String,
    isOverlay: Boolean,
    hideFooter: Boolean,
    bodyBackgroundColor: String,
    height: String,
    showCloseButton: {
      type: Boolean,
      default: true
    }
  }
})
export default class MBaseModal extends Vue {
  size = "normal";
  isOverlay = false;
  hideFooter = false;
  height = "normal";
  bodyBackgroundColor = "";
  showCloseButton = true;

  get modalSizeCss(): string {
    if (this.size === "sm") {
      return "max-w-md";
    } else if (this.size === "flat") {
      return "max-w-lg";
    } else if (this.size === "wide") {
      return "max-w-xl";
    }
    return "max-w-6xl";
  }
  get modalOverLayCss(): string {
    if (this.isOverlay) {
      return "overlay";
    }
    return "";
  }
  close() {
    this.$emit("close");
  }
}
