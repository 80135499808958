import { errorLogCollectionKey, ErrorLogData } from "@/entities/error";
import { Role } from "@/entities/role";
import firebase from "firebase/app";

export async function saveErrorLog(
  role: Role | null,
  code: string,
  message: string,
  summary: string
): Promise<void> {
  const data: ErrorLogData = {
    uid: role ? role.ref.id : "",
    code,
    message,
    summary: `[admin] ${summary}`,
    timestamp: Math.floor(Date.now() / 1000)
  };

  await firebase.firestore().collection(errorLogCollectionKey).add(data);
}
