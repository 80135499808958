// Student view (MessagePanel は除く)用のvuex

import { fetchBadges } from "@/api/badge";
import { saveErrorLog } from "@/api/error";
import { Badge } from "@/entities/badge";
import { Learning } from "@/entities/learning";
import { Module } from "vuex";
import { RootState } from "..";

export type StudentPageType =
  | "profile"
  | "history"
  | "learning"
  | "badge"
  | "submissions"
  | "todo"
  | "reflection"
  | "none";

interface StudentState {
  initialized: boolean;
  pageType: StudentPageType;
  badges: Badge[];
  learning: Learning | null;
}

const studentModule: Module<StudentState, RootState> = {
  namespaced: true,

  state: {
    initialized: false,
    pageType: "none",
    badges: [],
    learning: null
  },

  getters: {},

  mutations: {
    INITIALIZE(state) {
      state.initialized = true;
    },
    SET_PAGE_TYPE(state, type: StudentPageType) {
      state.pageType = type;
    },
    SET_BADGES(state, badges: Badge[]) {
      state.badges = badges;
    },
    SET_LEARNING(state, learning: Learning | null) {
      state.learning = learning;
    },
    CLEAR(state) {
      state.initialized = false;
      state.pageType = "none";
      state.badges = [];
      state.learning = null;
    }
  },

  actions: {
    async setup(context) {
      if (!context.state.initialized) {
        context.commit("INITIALIZE");

        // 初期化処理
        context.dispatch("getBadges");
      }
    },
    async getBadges(context) {
      try {
        const badges = await fetchBadges();
        context.commit("SET_BADGES", badges);
      } catch (e) {
        alert(`バッジ情報の取得に失敗しました\n\n${e}`);
        await saveErrorLog(
          context.rootState.role,
          e.code,
          e.message,
          "Failed to get badges"
        );
      }
    }
  }
};

export default studentModule;
