import { Module } from "vuex";
import { RootState } from "..";
import {
  SubmissionMaster,
  isExternalFileUrl
} from "@/entities/submission_master";
import { ThreadMaster } from "@/entities/thread_master";
import { getSubmissionMasterByClassroomIds } from "@/api/submission_master";
import { getThreadMasterByClassroomIds } from "@/api/thread_master";
import dayjs from "dayjs";
import { convertToUnixtimeFromDate } from "@/utils/date";

type TermString =
  | "過去4週間"
  | "過去7日間"
  | "本日"
  | "未来7日間"
  | "未来4週間"
  | "期間選択"
  | "";

interface SubmissionMasterState {
  load: boolean;
  submissionMasters: (SubmissionMaster | ThreadMaster)[];
  closedAtTerm: TermString;
  termStart: string;
  termStartTime: number;
  termEnd: string;
  termEndTime: number;
}

const submissionMasterModule: Module<SubmissionMasterState, RootState> = {
  namespaced: true,

  state: {
    load: false,
    submissionMasters: [],
    closedAtTerm: "過去7日間",
    termStart: "",
    termStartTime: 0,
    termEnd: "",
    termEndTime: 0
  },

  mutations: {
    SET_LOAD(state, load: boolean) {
      state.load = load;
    },
    SET_CLOSED_AT_TERM(state, term: TermString) {
      state.closedAtTerm = term;
      const now = dayjs().locale("ja");

      switch (term) {
        case "過去7日間":
          state.termStart = now.add(-6, "day").format("YYYY-MM-DD");
          state.termEnd = now.format("YYYY-MM-DD");
          break;
        case "過去4週間":
          state.termStart = now.add(-27, "day").format("YYYY-MM-DD");
          state.termEnd = now.format("YYYY-MM-DD");
          break;
        case "未来7日間":
          state.termStart = now.format("YYYY-MM-DD");
          state.termEnd = now.add(6, "day").format("YYYY-MM-DD");
          break;
        case "未来4週間":
          state.termStart = now.format("YYYY-MM-DD");
          state.termEnd = now.add(27, "day").format("YYYY-MM-DD");
          break;
        case "本日":
          state.termStart = now.format("YYYY-MM-DD");
          state.termEnd = now.format("YYYY-MM-DD");
          break;
        case "期間選択":
          return;
        default:
          return;
      }

      state.termStartTime = convertToUnixtimeFromDate(state.termStart);
      state.termEndTime = dayjs(state.termEnd)
        .locale("ja")
        .add(1, "day")
        .unix();
    },
    SET_TERM_START(state, start: string) {
      state.termStart = start;
      state.termStartTime = convertToUnixtimeFromDate(state.termStart);
      const endData = dayjs(state.termEnd).locale("ja").add(1, "day");
      const startData = dayjs(start).locale("ja");
      const dayCount = endData.diff(startData, "day");
      if (dayCount <= 0) {
        state.termEnd = start;
        state.termEndTime = dayjs(state.termEnd)
          .locale("ja")
          .add(1, "day")
          .unix();
      }
      state.closedAtTerm = "期間選択";
    },
    SET_TERM_END(state, end: string) {
      state.termEnd = end;
      state.termEndTime = dayjs(state.termEnd)
        .locale("ja")
        .add(1, "day")
        .unix();
      const endData = dayjs(end).locale("ja").add(1, "day");
      const startData = dayjs(state.termStart).locale("ja");
      const dayCount = endData.diff(startData, "day");
      if (dayCount <= 0) {
        state.termStart = end;
        state.termStartTime = convertToUnixtimeFromDate(state.termStart);
      }
      state.closedAtTerm = "期間選択";
    },

    SET_SUBMISSION_MASTERS(
      state,
      masters: (SubmissionMaster | ThreadMaster)[]
    ) {
      state.submissionMasters = masters;
    }
  },

  actions: {
    async getSubmissionMaster(
      context,
      args: {
        schoolId: string;
        classroomIds: string[];
      }
    ) {
      context.commit("SET_LOAD", true);
      const term = {
        start: context.state.termStartTime,
        end: context.state.termEndTime
      };
      const submissionMasters = await getSubmissionMasterByClassroomIds(
        args.schoolId,
        args.classroomIds,
        term
      );
      const threadMasters = await getThreadMasterByClassroomIds(
        args.schoolId,
        args.classroomIds,
        term
      );
      context.commit("SET_SUBMISSION_MASTERS", [
        ...submissionMasters,
        ...threadMasters
      ]);
      context.commit("SET_LOAD", false);
    }
  },
  getters: {
    uploadedFileUsages(localState: SubmissionMasterState) {
      return localState.submissionMasters
        .map(master => {
          if (!master.data.relatedLinks) return [];
          return master.data.relatedLinks
            .filter(link => !isExternalFileUrl(link.url))
            .map(link => ({
              masterRef: master.ref,
              url: link.url.split("?")[0]
            }));
        })
        .flat();
    }
  }
};

export default submissionMasterModule;
