
import { Options, Vue } from "vue-class-component";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import store from "@/store";
import {
  updateTutorAllowEmailNotify,
  updateTutorName,
  updateTutorPrompt
} from "@/api/tutor";
import { updateAdminName, updateAdminPrompt } from "@/api/admin";
import {
  getEmail,
  isVerifiedEmail,
  sendEmailVerification,
  sendResetPasswordEmail,
  updateEmail
} from "@/api/auth";
import { saveErrorLog } from "@/api/error";

@Options({
  components: {
    MBaseModal,
    MButton,
    MTextArea
  },
  emits: ["close"],
  watch: {
    allowEmailNotify: function() {
      this.onChangeAllowEmailNotify();
    },
    allowBadgeEmailNotify: function() {
      this.onChangeAllowEmailNotify();
    }
  }
})
export default class MUserModal extends Vue {
  username = "";
  prompt = "";
  email = "";
  allowEmailNotify = false;
  allowBadgeEmailNotify = false;

  get isVerifiedEmail(): boolean {
    return isVerifiedEmail();
  }

  get isTutor() {
    if (!store.state.role) {
      return false;
    }
    return store.state.role.data.type === "tutor";
  }

  get validName(): boolean {
    return this.username.length > 0;
  }

  get validEmail(): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email);
  }

  get validPrompt() {
    return this.prompt.length > 0 && this.prompt.length <= 1000;
  }

  get dynamicEmailCss(): string {
    if (!this.validEmail) {
      return "border-red-400";
    } else {
      return "border-primary-100";
    }
  }

  // async updatePrompt() {
  //   if (!store.state.role || !this.validPrompt) {
  //     return;
  //   }
  //   store.commit("START_LOADING", "プロンプト更新中...");

  //   const roleType = store.state.role.data.type;
  //   if (roleType === "tutor" && store.state.tutor) {
  //     await updateTutorPrompt(store.state.tutor.main.ref, this.prompt);
  //   } else if (roleType === "admin" && store.state.admin) {
  //     await updateAdminPrompt(store.state.admin.ref, this.prompt);
  //   }
  //   store.commit("END_LOADING");
  //   this.$router.go(0);
  // }

  async updateName() {
    if (!store.state.role || !this.validName) {
      return;
    }

    store.commit("START_LOADING", "名前更新中...");

    const roleType = store.state.role.data.type;
    if (roleType === "tutor" && store.state.tutor) {
      try {
        await updateTutorName(store.state.tutor.main.ref, this.username);
      } catch (e) {
        store.commit("END_LOADING");
        alert(`名前の更新に失敗しました\n\n${e}`);
        await saveErrorLog(
          store.state.role,
          e.code,
          e.message,
          "Failed to update user name"
        );
        return;
      }
    } else if (roleType === "admin" && store.state.admin) {
      try {
        await updateAdminName(store.state.admin.ref, this.username);
      } catch (e) {
        store.commit("END_LOADING");
        alert(`名前の更新に失敗しました\n\n${e}`);
        await saveErrorLog(
          store.state.role,
          e.code,
          e.message,
          "Failed to update user name"
        );
        return;
      }
    }
    store.commit("END_LOADING");
    this.$router.go(0);
  }

  async updateEmail() {
    if (!store.state.role || !this.validEmail) {
      return;
    }

    store.commit("START_LOADING", "メールアドレス更新中...");

    try {
      await updateEmail(this.email);
    } catch (e) {
      alert(
        `メールアドレスの更新に失敗しました。再度ログインして実行してみてください。\n\n${e}`
      );
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to update email"
      );
      store.dispatch("signOut");
      this.close();
      store.commit("END_LOADING");
      return;
    }

    if (store.state.role.data.type === "tutor" && store.state.tutor) {
      await updateTutorAllowEmailNotify(
        store.state.tutor.main.ref,
        false,
        false
      );
    }

    alert("メールアドレスの更新が完了しました");
    store.commit("END_LOADING");

    this.$router.go(0);
  }

  async sendVerifyEmail() {
    if (this.isVerifiedEmail) {
      return;
    }
    store.commit("START_LOADING", "メール送信中...");
    try {
      await sendEmailVerification();
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to send verify email"
      );
      return;
    }

    alert(
      "認証メールを送信しました。メールボックスをご確認ください。メールが見当たらない場合は迷惑メールフォルダもご確認ください。"
    );
    return;
  }

  async sendPasswordResetEmail() {
    if (!this.email) {
      return;
    }

    if (!this.isVerifiedEmail) {
      alert("パスワード再設定の前にメールアドレスの認証を完了させてください");
      return;
    }

    store.commit("START_LOADING", "メール送信中...");
    try {
      await sendResetPasswordEmail(this.email);
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(`パスワード再設定用のメール送信に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Faield to send reset password mail"
      );
      return;
    }

    alert(
      "パスワード再設定メールを送信しました。メールボックスをご確認ください。メールが見当たらない場合は迷惑メールフォルダもご確認ください。"
    );
  }

  async onChangeAllowEmailNotify() {
    if (
      (this.allowEmailNotify || this.allowBadgeEmailNotify) &&
      !this.isVerifiedEmail
    ) {
      alert(
        "通知メールを受け取るにはメールアドレスの認証が必要です。先にメール認証を完了させてください。"
      );
      this.allowEmailNotify = false;
      this.allowBadgeEmailNotify = false;
      return;
    }
    if (
      !store.state.tutor ||
      (store.state.tutor.main.data.allowEmailNotify === this.allowEmailNotify &&
        store.state.tutor.main.data.allowBadgeEmailNotify ===
          this.allowBadgeEmailNotify)
    ) {
      return;
    }

    try {
      await updateTutorAllowEmailNotify(
        store.state.tutor.main.ref,
        this.allowEmailNotify,
        this.allowBadgeEmailNotify
      );
      const newTutor = store.state.tutor;
      newTutor.main.data.allowEmailNotify = this.allowEmailNotify;
      newTutor.main.data.allowBadgeEmailNotify = this.allowBadgeEmailNotify;
      store.commit("SET_TUTOR", newTutor);
    } catch (e) {
      alert(e);
      this.allowEmailNotify =
        store.state.tutor.main.data.allowEmailNotify ?? false;
      this.allowBadgeEmailNotify =
        store.state.tutor.main.data.allowBadgeEmailNotify ?? false;
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to update tutor allow email notify"
      );
    }
  }

  close() {
    this.$emit("close");
  }

  created() {
    if (!store.state.role) {
      alert("ユーザー情報の取得に失敗しました");
      store.commit("SET_IS_OPEN_USER_MODAL", false);
      return;
    }
    const roleType = store.state.role.data.type;
    if (roleType === "tutor" && store.state.tutor) {
      this.username = store.state.tutor.main.data.name;
      this.allowEmailNotify =
        store.state.tutor.main.data.allowEmailNotify ?? false;
      this.allowBadgeEmailNotify =
        store.state.tutor.main.data.allowBadgeEmailNotify ?? false;
      // this.prompt = store.state.tutor.main.data.prompt ?? "";
    } else if (roleType === "admin" && store.state.admin) {
      this.username = store.state.admin.name;
      // this.prompt = store.state.admin.prompt ?? "";
      this.allowEmailNotify = false;
      this.allowBadgeEmailNotify = false;
    }

    this.email = getEmail();
  }
}
