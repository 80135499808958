import {
  convertToRegularlyReservation,
  RegularlyReservation,
  regularlyReservationCollectionKey,
  RegularlyReservationData
} from "@/entities/regularly_reservation";
import {
  convertToReservation,
  Reservation,
  reservationCollectionKey,
  ReservationData
} from "@/entities/reservation";
import dayjs from "dayjs";
import firebase from "firebase/app";
import "firebase/firestore";

export async function fetchReservationsOfStudent(
  studentRef: firebase.firestore.DocumentReference,
  timestamp = 0
): Promise<Reservation[]> {
  const snapshot = await studentRef
    .collection(reservationCollectionKey)
    .where("notifyTime", ">=", timestamp)
    .orderBy("notifyTime", "asc")
    .get();
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc => convertToReservation(doc.data(), doc.ref));
}

export async function createReservation(
  student: firebase.firestore.DocumentReference,
  reservationTime: number
): Promise<void> {
  const beforeMinute = 10;
  const beforeSeconds = beforeMinute * 60;
  const reservationData: ReservationData = {
    beforeMinute,
    notifyTime: Math.floor(reservationTime - beforeSeconds),
    notified: false
  };

  await student.collection(reservationCollectionKey).add(reservationData);
}

export async function createRegularlyReservation(
  student: firebase.firestore.DocumentReference,
  reservationTime: number,
  type: "day" | "week" | "month",
  time: string,
  weekday?: number,
  monthday?: number,
  deletionTime?: number
): Promise<void> {
  const beforeTime = dayjs(`2021-04-01 ${time}`)
    .add(-10, "minute")
    .format("HH:mm");
  const nextTime = reservationTime - 10 * 60;
  const data: RegularlyReservationData = {
    beforeMinute: 10,
    nextTime,
    type,
    time: beforeTime
  };

  if (type === "month" && monthday !== undefined) {
    data.monthday = monthday;
  } else if (type === "week" && weekday !== undefined) {
    data.weekday = weekday;
  }

  if (deletionTime) {
    data.deletionTime = deletionTime;
  }

  await student.collection(regularlyReservationCollectionKey).add(data);
}

export async function fetchRegularlyReservationsOfStudent(
  studentRef: firebase.firestore.DocumentReference
): Promise<RegularlyReservation[]> {
  const snapshot = await studentRef
    .collection(regularlyReservationCollectionKey)
    .get();
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc =>
    convertToRegularlyReservation(doc.data(), doc.ref)
  );
}

export async function getReservationsOf(
  studentRef: firebase.firestore.DocumentReference,
  timestamp = 0
): Promise<Reservation[]> {
  const snapshot = await studentRef
    .collection(reservationCollectionKey)
    .where("notifyTime", ">=", timestamp)
    .orderBy("notifyTime", "asc")
    .get();
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ref: doc.ref,
      data: {
        beforeMinute: data.beforeMinute,
        notifyTime: data.notifyTime,
        notified: data.notified
      }
    };
  });
}

export async function getRegularlyReservationsOf(
  studentRef: firebase.firestore.DocumentReference
): Promise<RegularlyReservation[]> {
  const snapshot = await studentRef
    .collection(regularlyReservationCollectionKey)
    .get();
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc =>
    convertToRegularlyReservation(doc.data(), doc.ref)
  );
}

export async function deleteReservation(
  ref: firebase.firestore.DocumentReference
): Promise<void> {
  await ref.delete();
}

export async function deleteRegularlyReservation(
  ref: firebase.firestore.DocumentReference
): Promise<void> {
  await ref.delete();
}
