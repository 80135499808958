
import { Options, Vue } from "vue-class-component";
@Options({
  emits: ["click"],
  props: {
    color: String,
    hoverColor: String,
    size: String,
    fontSize: String,
    fontWeight: String,
    disabled: Boolean,
    outline: Boolean,
    text: Boolean,
    link: String,
    external: Boolean,
    isDiv: Boolean
  }
})
export default class MButton extends Vue {
  color = "primary-500";
  hoverColor = "";
  size = "";
  fontSize = "sm";
  fontWeight = "medium";
  disabled = false;
  outline = false;
  text = false;
  link = "";
  external = false;
  isDiv = false;

  get dynamicClasses(): string {
    const classes: string[] = [
      "flex",
      "items-center",
      "rounded-md",
      `font-${this.fontWeight}`,
      `text-${this.fontSize}`,
      "transition-all",
      "duration-300"
    ];
    const hoverColor = this.hoverColor ? this.hoverColor : this.color;

    if (this.size == "sm") {
      classes.push("px-2");
      classes.push("py-1");
    } else {
      classes.push("px-4");
      classes.push("py-2");
    }

    if (!this.disabled) {
      classes.push("cursor-pointer");
    } else {
      classes.push("cursor-default");
    }

    if (this.outline) {
      classes.push("border");
      if (this.disabled) {
        classes.push("text-gray-300");
        classes.push("border-gray-300");
      } else {
        classes.push(`border-${this.color}`);
        classes.push(`text-${this.color}`);
        classes.push(`hover:border-${hoverColor}`);
        classes.push(`hover:text-${hoverColor}`);
      }
    } else if (this.text) {
      if (this.disabled) {
        classes.push("text-gray-300");
      } else {
        classes.push(`text-${this.color}`);
        classes.push(`hover:text-${hoverColor}`);
        if (this.color === hoverColor) {
          classes.push(`hover:bg-gray-100`);
        }
      }
    } else {
      classes.push("text-white");
      if (this.disabled) {
        classes.push("bg-gray-300");
      } else {
        classes.push(`bg-${this.color}`);
        classes.push(`hover:bg-${hoverColor}`);
      }
    }

    return classes.join(" ");
  }

  click() {
    if (!this.disabled) {
      this.$emit("click");
    }
  }
}
