// room関連view(RoomReservationList / RoomSettings / RoomStudentList)用のvuex

import { fetchRoomLabels } from "@/api/label";
import { getRegistrantStreamOfRoom } from "@/api/registrant";
import { getRoomStream } from "@/api/room";
import {
  fetchRoomRegularlyReservations,
  fetchRoomReservations
} from "@/api/room_reservation";
import { Label } from "@/entities/label";
import { convertToRegistrant, Registrant } from "@/entities/registrant";
import { convertToRoom, Room } from "@/entities/room";
import { RoomRegularlyReservation } from "@/entities/room_regularly_reservation";
import { RoomReservation } from "@/entities/room_reservation";
import firebase from "firebase/app";
import { Module } from "vuex";
import { RootState } from "..";

interface RoomState {
  initialized: boolean;
  roomStream: (() => void) | null;
  rooms: Room[];
  registrantStreams: (() => void)[];
  registrants: Registrant[];
  roomReservations: RoomReservation[];
  roomRegularlyReservations: RoomRegularlyReservation[];
  labels: Label[];
}

const roomModule: Module<RoomState, RootState> = {
  namespaced: true,

  state: {
    initialized: false,
    roomStream: null,
    rooms: [],
    registrantStreams: [],
    registrants: [],
    roomReservations: [],
    roomRegularlyReservations: [],
    labels: []
  },

  getters: {
    learningRegistrants: state =>
      state.registrants.filter(
        item => item.data.starts.length > item.data.ends.length
      )
  },

  mutations: {
    INITIALIZE(state) {
      state.initialized = true;
    },
    SET_ROOM_STREAM(state, stream: (() => void) | null) {
      state.roomStream = stream;
    },
    SET_ROOMS(state, rooms: Room[]) {
      state.rooms = rooms;
    },
    ADD_REGISTRANT_STREAM(state, stream: () => void) {
      state.registrantStreams.push(stream);
    },
    SET_REGISTRANTS(state, registrants: Registrant[]) {
      state.registrants = registrants;
    },
    SET_ROOM_RESERVATIONS(state, reservations: RoomReservation[]) {
      state.roomReservations = reservations;
    },
    SET_ROOM_REGULARLY_RESERVATIONS(
      state,
      reservations: RoomRegularlyReservation[]
    ) {
      state.roomRegularlyReservations = reservations;
    },
    SET_LABELS(state, labels: Label[]) {
      state.labels = labels;
    },
    CLEAR(state) {
      state.initialized = false;
      state.roomStream = null;
      state.rooms = [];
      state.registrantStreams = [];
      state.registrants = [];
      state.roomReservations = [];
      state.roomRegularlyReservations = [];
      state.labels = [];
    }
  },

  actions: {
    async changeRoomStream(context) {
      if (context.state.roomStream !== null) {
        context.state.roomStream();
      }

      context.commit("SET_ROOMS", []);

      const stream = getRoomStream().onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          let currentRooms = context.state.rooms;
          const changeRoom = convertToRoom(change.doc.data(), change.doc.ref);
          if (change.type === "added") {
            if (!changeRoom.data.school) {
              if (
                changeRoom.data.target &&
                (context.rootGetters.isAdmin ||
                  (context.rootState.schoolConfig &&
                    context.rootState.schoolConfig.data.target.id ===
                      changeRoom.data.target.id))
              ) {
                context.dispatch("addRegistrantStream", changeRoom.ref);
                currentRooms.push(changeRoom);
                currentRooms.sort((a, b) => a.data.start - b.data.start);
              } else {
                context.dispatch("addRegistrantStream", changeRoom.ref);
                currentRooms.push(changeRoom);
                currentRooms.sort((a, b) => a.data.start - b.data.start);
              }
            } else if (
              context.rootGetters.isAdmin ||
              (context.rootState.school &&
                context.rootState.school.ref.id === changeRoom.data.school.id)
            ) {
              context.dispatch("addRegistrantStream", changeRoom.ref);
              currentRooms.push(changeRoom);
              currentRooms.sort((a, b) => a.data.start - b.data.start);
            }
          } else if (change.type === "removed") {
            currentRooms = currentRooms.filter(
              room => room.ref.id !== changeRoom.ref.id
            );
          }
          if (this.state.schoolConfig) {
            // 管理人以外の場合、該当塾領域の学習室のみ表示させる
            currentRooms = currentRooms.filter(room => {
              if (room.data.target) {
                // 管理人が作成した学習室の領域と自身の領域が一致している && みんがく学習室を非表示にする設定がされていなければ、trueを返却
                return (
                  room.data.target!.id ===
                    this.state.schoolConfig?.data.target.id &&
                  !this.state.schoolConfig?.data.hideMingakuStudyRoom
                );
              } else {
                // roomのフィールドにtargetがない場合は、自身で作成した独自ルームのため、trueを返却する
                return !room.data.target;
              }
            });
          }
          context.commit("SET_ROOMS", currentRooms);
        });
      });
      context.commit("SET_ROOM_STREAM", stream);
    },
    async addRegistrantStream(
      context,
      roomRef: firebase.firestore.DocumentReference
    ) {
      const stream = getRegistrantStreamOfRoom(roomRef).onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          let currentRegistrants = context.state.registrants;
          const changeRegistrant = convertToRegistrant(
            change.doc.data(),
            change.doc.ref
          );
          if (change.type === "added") {
            currentRegistrants.push(changeRegistrant);
          } else if (change.type === "removed") {
            currentRegistrants = currentRegistrants.filter(
              room => room.ref.id !== changeRegistrant.ref.id
            );
          } else if (change.type === "modified") {
            let matchIndex = -1;
            currentRegistrants.forEach((registrant, i) => {
              if (registrant.ref.path === changeRegistrant.ref.path) {
                matchIndex = i;
              }
            });
            if (matchIndex >= 0) {
              currentRegistrants = currentRegistrants.filter(
                (_, i) => matchIndex !== i
              );
              currentRegistrants.push(changeRegistrant);
            } else {
              currentRegistrants.push(changeRegistrant);
            }
          }
          context.commit("SET_REGISTRANTS", currentRegistrants);
        });
      });
      context.commit("ADD_REGISTRANT_STREAM", stream);
    },
    async getRoomReservations(context) {
      const reservations = await fetchRoomReservations();
      context.commit("SET_ROOM_RESERVATIONS", reservations);
    },
    async getRoomRegularlyReservations(context) {
      const reservations = await fetchRoomRegularlyReservations();
      context.commit("SET_ROOM_REGULARLY_RESERVATIONS", reservations);
    },
    async getLabels(context) {
      const labels = await fetchRoomLabels(context.rootState.school?.ref);
      context.commit("SET_LABELS", labels);
    },
    async setup(context) {
      if (context.state.initialized) {
        return;
      }

      context.commit("INITIALIZE");
      context.dispatch("changeRoomStream");
      context.dispatch("getRoomReservations");
      context.dispatch("getRoomRegularlyReservations");
      context.dispatch("getLabels");
    }
  }
};

export default roomModule;
