import { getServiceTopics, getServiceTopicsOfSchool } from "@/api/service_topic";
import { ServiceTopic } from "@/entities/service_topic";
import { Module } from "vuex";
import { RootState } from "..";

interface ServiceTopicState {
  initialized: boolean;
  serviceTopics: ServiceTopic[];
  serviceTopicsOfSchool: ServiceTopic[];
}

const roomModule: Module<ServiceTopicState, RootState> = {
  namespaced: true,

  state: {
    initialized: false,
    serviceTopics: [],
    serviceTopicsOfSchool: []
  },

  getters: {},

  mutations: {
    INITIALIZE(state) {
      state.initialized = true;
    },
    SET_SERVICE_TOPICS(state, serviceTopics: ServiceTopic[]) {
      state.serviceTopics = serviceTopics;
    },
    SET_SERVICE_TOPICS_OF_SCHOOL(state, serviceTopics: ServiceTopic[]) {
      state.serviceTopicsOfSchool = serviceTopics;
    },
    CLEAR(state) {
      state.initialized = false;
      state.serviceTopics = [];
    }
  },

  actions: {
    async setup(context) {
      if (context.state.initialized) {
        return;
      }
      const serviceTopics = await getServiceTopics();
      const serviceTopicsOfShcool = await getServiceTopicsOfSchool();
      context.commit("SET_SERVICE_TOPICS", serviceTopics);
      context.commit("SET_SERVICE_TOPICS_OF_SCHOOL", serviceTopicsOfShcool);
      context.commit("INITIALIZE");
    }
  }
};

export default roomModule;
